<template>
  <NavBar>
    <div class="flex justify-center items-center h-screen" v-if="isLoading">
      <LoadingCom />
    </div>

    <div class="bg-white p-4 rounded-sm">
      <h1 class="font-bold my-4">Дансны төлөвлөгөө нэмэх</h1>
      <div id="addNewAccount"></div>

      <h1 class="mt-6 font-bold mb-4">12. Дансны төлөвлөгөө</h1>
      <div id="accountPlan"></div>
    </div>
  </NavBar>
</template>

<script>
import { ref, onMounted, reactive } from "vue";
import { useStore } from "vuex";

import Handsontable from "handsontable";
import NavBar from "@/components/ui/NavBar.vue";
import LoadingCom from "@/components/LoadingCom.vue";

export default {
  name: "DansHutlult",
  components: { NavBar, LoadingCom },
  setup() {
    const store = useStore();
    const data = ref();
    const hotInsertTable = ref();
    const isLoading = ref(false);

    const addNewAccount = reactive({
      kod: "",
      dansniiNer: "",
      ehniiUldegdel: 0,
      debitCredit: "",
      Stmur: "",
    });

    const hotSettings = {
      licenseKey: "non-commercial-and-evaluation",
      colHeaders: [
        "Код",
        "Дансны нэр",
        // "Эхний үлдэгдэл",
        // "D/C",
        // "СТ-ын мөр",
        "Огноо",
      ],
      data: [],
      columns: [
        { data: "account_id" },
        { data: "account_name" },
        {
          data: "account_type",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
        // { data: "debit_credit" },
        // { data: "st_mur" },
        { data: "formatted_created_at" },
      ],
    };

    onMounted(async () => {
      try {
        isLoading.value = true;
        await store.dispatch("fetchP12");
        data.value = store.getters.getP12;

        hotSettings.data = data.value;
        const container = document.getElementById("accountPlan");
        if (!container) {
          console.error("Container element not found");
          return;
        }
        new Handsontable(container, hotSettings);

        const addNewAccount = document.getElementById("addNewAccount");
        if (!addNewAccount) {
          console.error("addNewAccount element not found");
          return;
        }
        hotInsertTable.value = new Handsontable(
          addNewAccount,
          hotInsertSettings
        );

        isLoading.value = false;
      } catch (error) {
        isLoading.value = false;
        console.error("Error fetching data:", error);
      }
    });

    const hotInsertSettings = {
      licenseKey: "non-commercial-and-evaluation",
      colWidths: ["120", "120", "120", "120", "120", "60"],
      colHeaders: [
        "Код",
        "Дансны нэр",
        "Эхний үлдэгдэл",
        "D/C",
        "СТ-ын мөр",
        "",
      ],
      minRows: 1,
      maxRows: 1,
      columns: [
        { data: "1" },
        { data: "2" },
        { data: "3" },
        { data: "4", type: "dropdown", source: ["D", "C"] },
        { data: "5" },
        {
          data: "6",
          readOnly: true,
          renderer: async (instance, td) => {
            td.innerHTML =
              '<button class="save-btn bg-gray-300 rounded-md px-2 hover:bg-primary hover:text-white font-bold my-1">Save</button>';
            const saveButton = td.querySelector(".save-btn");
            if (saveButton) {
              saveButton.addEventListener("click", async () => {
                saveNewAccountPlan();
              });
            }
          },
        },
      ],
    };

    const saveNewAccountPlan = async () => {
      const [kod, dansniiNer, ehniiUldegdel, debitCredit, Stmur] =
        hotInsertTable.value.getData()[0];

      if (!kod) return alert("Дансны код оруулна уу");
      if (!ehniiUldegdel) return alert("Эхний үлдэгдэлээ оруулна уу");
      if (!dansniiNer) return alert("Дансны нэр оруулна уу");
      if (!debitCredit) return alert("D/C оруулна уу");
      if (!Stmur) return alert("СТ-ын мөр оруулна уу");

      addNewAccount.kod = kod;
      addNewAccount.dansniiNer = dansniiNer;
      addNewAccount.ehniiUldegdel = ehniiUldegdel;
      addNewAccount.debitCredit = debitCredit;
      addNewAccount.Stmur = Stmur;

      // const date = new Date();
      // addNewAccount.mgtmur =
      //   date.getFullYear() + "." + date.getMonth() + "." + date.getDate();

      const res = await store.dispatch("addNewP12", addNewAccount);
      if (res.status == 200) window.location.reload();
    };

    return {
      data,
      hotSettings,
      addNewAccount,
      hotInsertSettings,
      isLoading,
    };
  },
};
</script>
