<template>
  <NavBar>
    <div class="p-4 bg-[#022239] flex justify-between items-center">
      <div class="flex gap-4 text-[#CDC9C5]">
        <button
          class="bg-[#02324B] text-[#CDC9C5] py-3 px-4 rounded-sm shadow-lg font-bold"
        >
          <a
            href="/employee-list"
            class="flex justify-center items-center gap-3"
          >
            <font-awesome-icon
              icon="fa-download"
              class="h-4 w-4 text-gary-50"
            />
            <p>1. Ажилтаны мэдээлэл</p>
          </a>
        </button>

        <button
          class="bg-[#02324B] text-[#CDC9C5] py-3 px-4 rounded-sm shadow-lg font-bold"
        >
          <a href="/salary" class="flex justify-center items-center gap-3">
            <font-awesome-icon
              icon="fa-download"
              class="h-4 w-4 text-gary-50"
            />
            <p>2. Цаг бүртгэл</p>
          </a>
        </button>

        <button
          class="bg-[#02324B] text-[#CDC9C5] py-3 px-4 rounded-sm shadow-lg font-bold"
        >
          <a href="/salary" class="flex justify-center items-center gap-3">
            <font-awesome-icon
              icon="fa-download"
              class="h-4 w-4 text-gary-50"
            />
            <p>3. Цалин тооцоо</p>
          </a>
        </button>

        <button
          class="bg-[#066BAF] text-[#CDC9C5] py-3 px-4 rounded-sm shadow-lg font-bold"
        >
          <a
            href="/const-value"
            class="flex justify-center items-center gap-3"
            :class="{
              'text-[#CDC9C5]': $route.path === '/const-value',
            }"
          >
            <font-awesome-icon icon="fa-clock" class="h-4 w-4 text-gary-50" />
            <p>4.Тогтмол утгууд</p>
          </a>
        </button>
      </div>
      <div class="flex gap-4">
        <button
          class="bg-[#049758] text-[#CDC9C5] py-3 px-4 flex justify-center items-center gap-3 rounded-sm shadow-lg font-bold"
        >
          <font-awesome-icon icon="fa-download" class="h-4 w-4 text-gary-50" />
          <p>Татах</p>
        </button>

        <button
          class="bg-[#049758] text-[#CDC9C5] py-3 px-4 flex justify-center items-center gap-3 rounded-sm shadow-lg font-bold"
          @click="getEmployeesWithDetails"
        >
          <font-awesome-icon icon="fa-add" class="h-4 w-4 text-gary-50" />
          <p>Нэрс татах</p>
        </button>

        <button
          class="bg-[#049758] text-[#CDC9C5] py-3 px-4 flex justify-center items-center gap-3 rounded-sm shadow-lg font-bold"
          @click="saveHour"
        >
          <font-awesome-icon
            icon="fa-floppy-disk"
            class="h-4 w-4 text-gary-50"
          />
          <p>Хадгалах</p>
        </button>
      </div>
    </div>
    <div class="p-4">
      <!-- <ul
        class="flex items-center text-[#717271] border border-[#CCCCCC] w-[600px] mb-12"
      >
        <li class="flex-1 border-r p-2 text-center">1. Ажилтаны мэдээлэл</li>
        <li class="flex-1 bg-[#F1F0F1] text-gray-400 border-r p-2 text-center">
          2. Цаг бүртгэл
        </li>
        <li class="flex-1 border-r p-2 text-center">3. Цалин тооцоо</li>
        <li class="flex-1 p-2 text-center">4.Тогтмол утгууд</li>
      </ul> -->

      <HotTable
        v-if="contValueData && contValueData?.data?.length > 0"
        :settings="tableSettings"
        :data="contValueData.data"
        ref="hotRef"
      />
      <HotTable v-else :settings="tableSettings" ref="hotRef" />
    </div>
  </NavBar>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "handsontable/dist/handsontable.full.css";

import NavBar from "@/components/ui/NavBar.vue";

registerAllModules();
export default {
  name: "ConstValues",
  components: { NavBar, HotTable },

  setup() {
    const store = useStore();
    const contValueData = ref([]);
    const hotRef = ref(null);
    const loading = ref(false);

    const fetchEmployeesWorkedHour = async () => {
      contValueData.value = await store.dispatch("fetchConstValue");
    };

    onMounted(async () => {
      await fetchEmployeesWorkedHour();
    });

    const numberFormatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    const safeHtmlRenderer = (instance, td, row, col, prop, value) => {
      if (!isNaN(value) && value !== null) {
        value = numberFormatter.format(value);
      }
      td.textContent = value;
      td.classList.add("htCenter", "htMiddle");
      td.style.color = "rgb(71, 85, 105)";
    };

    const tableSettings = {
      licenseKey: "non-commercial-and-evaluation",
      colWidths: 150,
      rowHeaders: true,
      rowHeights: 30,
      maxRows: 1,
      colHeaders: ["Хүүхдийн мөнгө", "Хоолны мөнгө", "Унааны мөнгө"],
      columns: [
        { data: "huuhdii_mongo", renderer: safeHtmlRenderer },
        { data: "hoolnii_mongo", renderer: safeHtmlRenderer },
        { data: "unaanii_mongo", renderer: safeHtmlRenderer },
      ],
      afterChange: async (changes, source) => {
        if (source === "edit" || source === "paste") {
          for (const [row, col, oldValue, newValue] of changes) {
            if (oldValue === newValue) {
              return;
            }

            const hotInstance = hotRef.value.hotInstance;
            const rowData = hotInstance.getDataAtRow(row);
            const id = rowData[0];
            console.log(id);
            try {
              loading.value = true;
              const res = await store.dispatch("insertConstValue", {
                value: newValue,
                columnName: col,
              });
              if (
                res.data.message &&
                res.data.message == "inserted successfully"
              )
                // loading.value = false;
                window.location.reload();
            } catch (e) {
              console.error(e);
            }
          }
        }
      },
    };

    return { hotRef, tableSettings, contValueData };
  },
};
</script>
