<template>
  <canvas ref="chartRef" style="height: 620px; width: 620px"></canvas>
</template>

<script>
import { ref, onMounted } from "vue";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default {
  name: "ChartCom",
  props: {
    data: {
      type: Object,
    },
  },

  setup(props) {
    const chartRef = ref(null);

    const initializeChart = () => {
      if (!chartRef.value) return;

      const ctx = chartRef.value.getContext("2d");
      new Chart(ctx, {
        type: props.data.type,
        data: props.data.data,
        options: props.data.options,
      });
    };

    onMounted(initializeChart);

    return { chartRef };
  },
};
</script>
