<template>
  <NavBar>
    <div class="flex justify-center items-center h-screen" v-if="isLoading">
      <LoadingCom />
    </div>

    <div class="bg-white p-4 rounded-sm">
      <h1 class="py-2 font-bold">Ажилтан нэмэх</h1>
      <div id="input-wage"></div>
      <h1 class="py-4 font-bold">Цалингийн хүснэгт</h1>
      <div id="wageList"></div>

      <!-- show total wage -->
      <table class="my-10">
        <thead class="border-b bg-slate-300">
          <tr>
            <th>Тооцоолол</th>
            <th class="px-10 py-2">Дүн</th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-b pb-6">
            <td>Нийт гарт олгох дүн</td>
            <td class="px-10 py-2">
              {{ totalWage.totalGartOlgoh.toLocaleString() }}
            </td>
          </tr>
          <tr class="border-b py-2">
            <td>Нийт ХХОАТ шилжүүлэх дүн</td>
            <td class="px-10 py-2">
              {{ totalWage.totalHHOAT.toLocaleString() }}
            </td>
          </tr>
          <tr class="border-b py-2">
            <td>Нийт НДШ шилжүүлэх дүн</td>
            <td class="px-10 py-2">
              {{
                (
                  totalWage.totalOrganizationNDSH + totalWage.totalNDSH
                ).toLocaleString()
              }}
            </td>
          </tr>
          <tr class="border-b">
            <td class="font-bold">Нийт цалингийн зардлын дүн</td>
            <td class="px-10 py-2 font-bold">
              {{
                (
                  totalWage.totalGartOlgoh +
                  totalWage.totalHHOAT +
                  totalWage.totalOrganizationNDSH +
                  totalWage.totalNDSH
                ).toLocaleString()
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!data">
      <p>Мэдээлэл олдсонгүй!!!</p>
    </div>

    <!-- Monshibisaki ************ -->
    <div>
      <button @click="addRow">Add Row</button>
      <div id="hot"></div>
    </div>
  </NavBar>
</template>

<script>
import { ref, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import Handsontable from "handsontable";
import "handsontable/dist/handsontable.full.min.css";

import NavBar from "@/components/ui/NavBar.vue";
import LoadingCom from "@/components/LoadingCom.vue";

export default {
  name: "TsalinHuls",
  components: { NavBar, LoadingCom },
  setup() {
    const store = useStore();
    const data = ref();
    const hotInsertTable = ref();
    const isLoading = ref(false);

    const totalWage = reactive({
      totalGartOlgoh: 0,
      totalHHOAT: 0,
      totalNDSH: 0,
      totalOrganizationNDSH: 0,
    });

    const wageData = reactive({
      registerNum: "",
      lastName: "",
      name: "",
      wage: "",
      workHours: "",
      workedHours: "",
    });

    const hotSettings = {
      licenseKey: "non-commercial-and-evaluation",
      data: [],
      // width: "1200px",
      // stretchH: "all",
      autoWrapRow: true,
      autoWrapCol: true,
      colHeaders: [
        "Сар",
        "Регистер",
        "Овог",
        "Нэр",
        "Үндсэн </br> цалин",
        "Ажилбал </br> зохих цаг",
        "Ажилласан </br> цаг",
        "Бодогдсон </br> цалин",
        "НДШ",
        "Татвар тооцох </br> орлого",
        "ХХОАТ",
        "Нийт </br> суутгал",
        "Олговол </br> зохих",
        "Урьдчилгаа",
        "Гарт олгох </br> цалин",
        "БНДШ",
      ],
      rowHeights: 30,
      columns: [
        { data: "formatted_created_at" },
        { data: "register_num" },
        { data: "last_name" },
        { data: "first_name" },
        {
          data: "basic_wage",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
        { data: "work_hours" },
        { data: "worked_hours" },
        {
          data: "estimated_wage",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
        {
          data: "social_security",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
        {
          data: "taxable_income",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
        {
          data: "personal_income_tax",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
        {
          data: "total_withholding_amount",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
        {
          data: "wage_amount_to_give",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
        {
          data: "advance_money",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
        {
          data: "total_amount_to_give",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
        {
          data: "social_security_provided_by_organization",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
      ],
    };

    const initializeHandsontable = () => {
      const container = document.getElementById("input-wage");
      if (!container) {
        return;
      }
      hotInsertTable.value = new Handsontable(container, hotInsertSettings);
      hotSettings.data = data.value;
      // const containerWage = document.getElementById("wageList");
      const containerWage = document.querySelector("#wageList");

      if (!containerWage) {
        return;
      }
      new Handsontable(containerWage, hotSettings);
    };

    const hotInsertSettings = {
      licenseKey: "non-commercial-and-evaluation",
      colWidths: ["120", "120", "140", "140", "140", "140", "60"],
      colHeaders: [
        "Овог",
        "Нэр",
        "Регистрийн дугаар",
        "Үндсэн цалин",
        "Ажилбал зохих цаг",
        "Ажилласан цаг",
        "",
      ],
      colHeader: true,
      minRows: 1,
      maxRows: 1,
      columns: [
        { data: "1" },
        { data: "2" },
        { data: "3" },
        { data: "4" },
        { data: "5" },
        { data: "6" },
        {
          data: "7",
          readOnly: true,
          renderer: async (instance, td) => {
            td.innerHTML =
              '<button class="save-btn bg-gray-300 rounded-md px-2 hover:bg-primary hover:text-white font-bold my-1">Save</button>';
            const saveButton = td.querySelector(".save-btn");
            if (saveButton) {
              saveButton.addEventListener("click", async () => {
                const [
                  lastName,
                  name,
                  registerNum,
                  wage,
                  workHours,
                  workedHours,
                ] = hotInsertTable.value.getData()[0];

                if (!lastName) return alert("Овог оруулна уу");
                if (!name) return alert("Нэр оруулна уу");
                if (!registerNum) return alert("Регистрийн дугаар оруулна уу");
                if (!wage) return alert("Үндсэн цалинг оруулна уу");
                if (!workHours) return alert("Ажилбал зохих цагийг оруулна уу");
                if (!workedHours) return alert("Ажилласан цагийг оруулна уу");

                wageData.lastName = lastName;
                wageData.name = name;
                wageData.registerNum = registerNum;
                wageData.wage = wage;
                wageData.workHours = workHours;
                wageData.workedHours = workedHours;
                const result = await store.dispatch("addNewEmployee", wageData);
                if (result.status === 200) window.location.reload();
              });
            }
          },
        },
      ],
    };

    const calculateTotalWage = () => {
      if (!data.value || data.value.length === 0) {
        return;
      }

      totalWage.totalGartOlgoh = data.value
        .map((item) => parseFloat(item.total_amount_to_give))
        .reduce((sum, value) => sum + value, 0);

      totalWage.totalHHOAT = data.value.reduce(
        (sum, item) => sum + parseFloat(item.personal_income_tax),
        0
      );

      totalWage.totalNDSH = data.value.reduce(
        (sum, item) => sum + parseFloat(item.social_security),
        0
      );

      totalWage.totalOrganizationNDSH = data.value.reduce(
        (sum, item) =>
          sum + parseFloat(item.social_security_provided_by_organization),
        0
      );
    };

    onMounted(async () => {
      try {
        isLoading.value = true;
        await store.dispatch("getWageList");
        data.value = store.getters.getP1;
        initializeHandsontable();
        calculateTotalWage();
        isLoading.value = false;
      } catch (error) {
        isLoading.value = false;
        console.error(error);
      }
    });

    const hotInstance = ref();

    const fixedData = ref([
      ["A1", "B1", "C1"],
      ["A2", "B2", "C2"],
    ]);

    const tableSettings = ref({
      data: fixedData.value,
      licenseKey: "non-commercial-and-evaluation",
      colWidths: [
        50, 150, 150, 130, 130, 150, 150, 150, 150, 80, 80, 100, 120, 100, 130,
        100, 100, 130, 80, 130, 130, 150, 150,
      ],
      width: "100%",
      height: "auto",
      fixedColumnsStart: 2,
      contextMenu: true,
      colHeaders: [
        "Д/Д",
        "Ажилчдын </br>нэрс",
        "Албан</br> тушаал",
        "Даатгуулагчийн</br> төрөл",
        "Ажил </br>мэргэжлийн </br>ангилал",
        "МУЧ,</br> ажлын</br> туршлагын</br> нэмэгдэл",
        "Удирдан </br>ЗБ ур</br> чадварын </br> нэмэгдэл",
        "Ажилсан </br> жилийн </br> нэмэгдэл",
        "Хоол </br> унааны </br> мөнгө",
        "БО-н 1%",
        "Хүүхдийн мөнгө",
        "Амралтын цалин",
        "Урам-лал,</br>Шагнал,</br>Бонус</br>цалин",
        "Бусад",
        "Татварын</br> өмнөх </br>нийт </br>цалин",
        "ЭМНДШ</br>/хувь хүн/",
        "ХХОАТ",
        "Урьдчилгаа</br> цалин</br>/дансаар/",
        "БО-н 1%</br>/дансаар/",
        "Шагнал, </br>урамшуулал",
        "Урьдчилан</br>олгосон</br> цалин,</br> Э.амралт",
        "Бусад </br>суутгал, </br>/бэлэн олгосон/",
        "Олгох </br>сүүл цалин</br> /дансаар/ ",
      ],
      columns: [
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
    });

    onMounted(() => {
      const container = document.getElementById("hot");
      if (!container) {
        return;
      }

      hotInstance.value = new Handsontable(container, tableSettings.value);
    });

    const addRow = () => {
      hotInstance.value.alter(
        "insert_row_below",
        hotInstance.value.countRows()
      );
    };

    return {
      data,
      hotSettings,
      isLoading,
      totalWage,
      addRow,
    };
  },
};
</script>

<style>
@import "~handsontable/dist/handsontable.full.css";
</style>
