<template>
  <BowsysNavbar>
    <div>Goods Page</div>
  </BowsysNavbar>
</template>

<script>
import BowsysNavbar from "@/components/bowsys/BowsysNavbar.vue";

export default {
  name: "GoodsPage",
  components: { BowsysNavbar },
  setup() {
    return {};
  },
};
</script>
