import { clientInstance } from '../lib/instance'

export default {
  state() {
    return {
      p1: [],
      p2: [],
      p3: [],
      p4: [],
      p5: [],
      // p6: [],
      p7: [],
      p8: [],
      // p9: [],
      p10: [],
      p11: [],
      p12: [],
      p13: [],
    }
  },

  getters: {
    getP1(state) {
      return state.p1
    },
    getP2(state) {
      return state.p2
    },
    getP3(state) {
      return state.p3
    },
    getP4(state) {
      return state.p4
    },
    getP5(state) {
      return state.p5
    },
    // getP6(state) {
    //   return state.p6;
    // },
    getP7(state) {
      return state.p7
    },
    getP8(state) {
      return state.p8
    },
    getP10(state) {
      return state.p10
    },
    getP11(state) {
      return state.p11
    },
    getP12(state) {
      return state.p12
    },
  },

  mutations: {
    setP1(state, payload) {
      state.p1 = payload
    },
    setP2(state, payload) {
      state.p2 = payload
    },
    setP3(state, payload) {
      state.p3 = payload
    },
    setP4(state, payload) {
      state.p4 = payload
    },
    setP5(state, payload) {
      state.p5 = payload
    },
    // setP6(state, payload) {
    //   state.p6 = payload;
    // },
    setP7(state, payload) {
      state.p7 = payload
    },
    setP8(state, payload) {
      state.p8 = payload
    },
    setP10(state, payload) {
      state.p10 = payload
    },
    setP11(state, payload) {
      state.p11 = payload
    },
    setP12(state, payload) {
      state.p12 = payload
    },
  },

  actions: {
    // LOGIN
    async checkidToken(_, payload) {
      try {
        return await clientInstance.post('/checkidToken', payload)
      } catch (error) {
        console.error('Error:', error)
        return error
      }
    },

    // Add a new company and create a new database using the company name. - ODOOGOOG AJILAHGUI
    async addNewCompany(_, payload) {
      try {
        const res = await clientInstance.post('/addNewCompannyAndCreateDatabase', payload)
        console.log('add company--->', res)
      } catch (error) {
        console.error('Error:', error)
        return error
      }
    },

    // fetch all Company - ODOOGOOG AJILAHGUI BAIH
    async fetchAllCompany() {
      try {
        return await clientInstance.get('/getAllCompany2')
      } catch (error) {
        console.error('Error:', error)
        return error
      }
    },

    // get the wage list of all employees
    async getWageList({ commit }) {
      // const storedCompanyInfo = JSON.parse(localStorage.getItem("companyInfo"));
      try {
        const res = await clientInstance.get('/getWageList')
        commit('setP1', res.data.result)
      } catch (error) {
        return error
      }
    },

    // MONSHIBASAKI - TSAG BURTGEL
    async insertHour(_, payload) {
      try {
        const res = await clientInstance.post('/insertEmplyeesHours', payload)
        return res.data
      } catch (error) {
        console.error('Error:', error)
        return error
      }
    },

    // MONSHIBASAKI - TSALIN BODOH tsag burtgeh
    async insertAndCountSalaray(_, payload) {
      try {
        return await clientInstance.post('/insertAndCountSalaray', payload)
      } catch (error) {
        console.error('Error:', error)
        return error
      }
    },

    // MONSHIBASAKI - tsag burtgeh haruulah
    async fetchEmployeesWorkedHour() {
      try {
        return await clientInstance.get('/getEmployeesWorkedHour')
      } catch (error) {
        console.error('Error:', error)
        return error
      }
    },

    // MONSHIBASAKI - TSALIN BODOH togtmol utguud oruulah
    async insertConstValue(_, payload) {
      try {
        return await clientInstance.post('/insertConstValue', payload)
      } catch (error) {
        console.error('Error:', error)
        return error
      }
    },

    // MONSHIBASAKI - TSALIN BODOH togtmol utguud avah
    async fetchConstValue() {
      try {
        return await clientInstance.get('/getConstValue')
      } catch (error) {
        console.error('Error:', error)
        return error
      }
    },

    // Add the new employee's details to the db and calculate the new employee's wage.
    async addNewEmployee(_, payload) {
      try {
        return await clientInstance.post('/addNewEmployee', payload)
      } catch (error) {
        console.error('Error:', error)
        return error
      }
    },

    // BARAA MATERIAL
    async fetchP2({ commit }) {
      try {
        const res = await clientInstance.get('/p2')
        commit('setP2', res.data.a2)
      } catch (error) {
        return error
      }
    },

    // UNDSEN HURUNGU
    async fetchP3({ commit }) {
      try {
        const res = await clientInstance.get('/p3')
        commit('setP3', res.data.a3)
      } catch (error) {
        return error
      }
    },

    // 4 SHEET - NOT CLEAR
    async fetchP4({ commit }) {
      try {
        const res = await clientInstance.get('/p4')
        commit('setP4', res.data.a4)
      } catch (error) {
        return error
      }
    },

    // get YRUNHII JURNAL list
    async fetchP5({ commit }) {
      try {
        const res = await clientInstance.get('/p5')
        commit('setP5', res.data.a5)
      } catch (error) {
        return error
      }
    },

    // insert a new journal to yrunhii jurnal list
    async insertNewJournal(_, payload) {
      try {
        return await clientInstance.post('/insertUpdateDeleteLog', {
          data: payload,
        })
      } catch (error) {
        console.error(error)
      }
    },

    // get guilgee balance list and a grapic data
    async fetchAllTransaction() {
      try {
        const res = await clientInstance.get('/getAndIntegrateAllTransaction')
        return res.data
      } catch (error) {
        return error
      }
    },

    // HYNALTIIN HUUDAS
    async fetchP7({ commit }) {
      try {
        const res = await clientInstance.get('/p7')
        commit('setP7', res.data.a7)
      } catch (error) {
        return error
      }
    },

    async fetchP8({ commit }) {
      try {
        const res = await clientInstance.get('/p8')
        commit('setP8', res.data.a8)
      } catch (error) {
        return error
      }
    },

    // CT1
    async fetchBalanceSheetsByYearAndSeason(_, payload) {
      try {
        const res = await clientInstance.post('/getBalanceSheetsByYearAndSeason', payload)
        return res.data
      } catch (error) {
        return error
      }
    },

    // CT2
    async fetchIncomeStatementByYearAndSeason(_, payload) {
      try {
        const res = await clientInstance.post('/getIncomeStatementByYearAndSeason', payload)
        return res.data
      } catch (error) {
        return error
      }
    },

    // CT 3
    async fetchStatementOfShareHoldersEquity(_, payload) {
      try {
        const res = await clientInstance.post('/getStatementOfShareHoldersEquity', payload)
        return res.data
      } catch (error) {
        return error
      }
    },

    // CT 4
    async fetchCashFlowStatement(_, payload) {
      try {
        const res = await clientInstance.post('/getCashFlowStatement', payload)
        return res.data
      } catch (error) {
        return error
      }
    },

    // get account plan list
    async fetchP12({ commit }) {
      try {
        const res = await clientInstance.get('/p12')
        commit('setP12', res.data.a12)
      } catch (error) {
        return error
      }
    },

    // insert new account plan to bd table.
    async addNewP12(_, payload) {
      try {
        return await clientInstance.post('/addNewP12', payload)
      } catch (error) {
        console.error('Error:', error)
        return error
      }
    },
  },
}
