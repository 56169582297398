<template>
  <NavBar>
    <div class="p-4 bg-[#022239] flex justify-between items-center">
      <div class="flex gap-4 text-[#CDC9C5]">
        <button
          class="bg-[#02324B] text-[#CDC9C5] py-3 px-4 rounded-sm shadow-lg font-bold"
        >
          <a
            href="/employee-list"
            class="flex justify-center items-center gap-3"
          >
            <font-awesome-icon
              icon="fa-download"
              class="h-4 w-4 text-gary-50"
            />
            <p>1. Ажилтаны мэдээлэл</p>
          </a>
        </button>

        <button
          class="bg-[#02324B] text-[#CDC9C5] py-3 px-4 rounded-sm shadow-lg font-bold"
        >
          <a
            href="/time-register"
            class="flex justify-center items-center gap-3"
          >
            <font-awesome-icon icon="fa-clock" class="h-4 w-4 text-gary-50" />
            <p>2. Цаг бүртгэл</p>
          </a>
        </button>

        <button
          class="bg-[#066BAF] text-[#CDC9C5] py-3 px-4 rounded-sm shadow-lg font-bold"
        >
          <a
            href="/salary"
            class="flex justify-center items-center gap-3"
            :class="{
              'text-[#CDC9C5]': $route.path === '/salary',
            }"
          >
            <font-awesome-icon
              icon="fa-download"
              class="h-4 w-4 text-gary-50"
            />
            <p>3. Цалин тооцоо</p>
          </a>
        </button>

        <button
          class="bg-[#02324B] text-[#CDC9C5] py-3 px-4 rounded-sm shadow-lg font-bold"
        >
          <a href="/const-value" class="flex justify-center items-center gap-3">
            <font-awesome-icon icon="fa-clock" class="h-4 w-4 text-gary-50" />
            <p>4.Тогтмол утгууд</p>
          </a>
        </button>
      </div>
      <div class="flex gap-4">
        <button
          class="bg-[#049758] text-[#CDC9C5] py-3 px-4 flex justify-center items-center gap-3 rounded-sm shadow-lg font-bold"
        >
          <font-awesome-icon icon="fa-download" class="h-4 w-4 text-gary-50" />
          <p>Татах</p>
        </button>

        <button
          class="bg-[#049758] text-[#CDC9C5] py-3 px-4 flex justify-center items-center gap-3 rounded-sm shadow-lg font-bold"
          @click="getEmployeesWithDetails"
        >
          <font-awesome-icon icon="fa-add" class="h-4 w-4 text-gary-50" />
          <p>Нэрс татах</p>
        </button>

        <button
          class="bg-[#049758] text-[#CDC9C5] py-3 px-4 flex justify-center items-center gap-3 rounded-sm shadow-lg font-bold"
          @click="saveSalaryData"
        >
          <font-awesome-icon
            icon="fa-floppy-disk"
            class="h-4 w-4 text-gary-50"
          />
          <p>Хадгалах</p>
        </button>
      </div>
    </div>

    <div class="flex justify-end px-4 gap-3 my-8">
      <div class="relative w-52">
        <div
          class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
        >
          <font-awesome-icon
            icon="fa-search"
            class="h-3.5 w-3.5 text-gray-400"
          />
        </div>
        <input
          type="search"
          id="default-search"
          class="font-medium block w-full p-3 ps-10 text-gray-400 border border-gray-200 rounded-sm bg-gray-100 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Хайх..."
          required
        />
      </div>
      <div
        class="flex justify-center items-center w-10 border-gray-200 rounded-sm bg-gray-100"
      >
        <font-awesome-icon
          icon="fa-calendar-days"
          class="h-4 w-4 text-gray-400"
        />
      </div>
      <div class="relative">
        <button
          id="dropdownDefaultButton"
          data-dropdown-toggle="dropdown"
          class="font-medium rounded-sm w-44 px-5 py-3 flex justify-between items-center bg-gray-100 text-gray-400 border border-gray-200"
          type="button"
          @click="filter"
        >
          Dropdown button
          <font-awesome-icon
            icon="fa-chevron-down"
            class="h-3.5 w-3.5 text-gray-400"
          />
        </button>

        <!-- Dropdown menu -->
        <div
          v-if="activeStatus"
          class="z-[9998] bg-white divide-y divide-gray-100 rounded-sm shadow w-44 absolute top-12"
        >
          <ul class="py-2 text-sm text-gray-700">
            <li>
              <a href="#" class="block px-4 py-2 hover:bg-gray-100"
                >Dashboard</a
              >
            </li>
            <li>
              <a href="#" class="block px-4 py-2 hover:bg-gray-100">Settings</a>
            </li>
            <li>
              <a href="#" class="block px-4 py-2 hover:bg-gray-100">Earnings</a>
            </li>
            <li>
              <a href="#" class="block px-4 py-2 hover:bg-gray-100">Sign out</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="relative">
        <button
          id="dropdownDefaultButton"
          data-dropdown-toggle="dropdown"
          class="font-medium rounded-sm w-44 px-5 py-3 flex justify-between items-center bg-gray-100 text-gray-400 border border-gray-200"
          type="button"
          @click="filter"
        >
          Dropdown button
          <font-awesome-icon
            icon="fa-chevron-down"
            class="h-3.5 w-3.5 text-gray-400"
          />
        </button>

        <!-- Dropdown menu -->
        <div
          v-if="activeStatus"
          class="z-[9998] bg-white divide-y divide-gray-100 rounded-sm shadow w-44 absolute top-12"
        >
          <ul class="py-2 text-sm text-gray-700">
            <li>
              <a href="#" class="block px-4 py-2 hover:bg-gray-100"
                >Dashboard</a
              >
            </li>
            <li>
              <a href="#" class="block px-4 py-2 hover:bg-gray-100">Settings</a>
            </li>
            <li>
              <a href="#" class="block px-4 py-2 hover:bg-gray-100">Earnings</a>
            </li>
            <li>
              <a href="#" class="block px-4 py-2 hover:bg-gray-100">Sign out</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="relative">
        <button
          id="dropdownDefaultButton"
          data-dropdown-toggle="dropdown"
          class="font-medium rounded-sm w-44 px-5 py-3 flex justify-between items-center bg-gray-100 text-gray-400 border border-gray-200"
          type="button"
          @click="filter"
        >
          Dropdown button
          <font-awesome-icon
            icon="fa-chevron-down"
            class="h-3.5 w-3.5 text-gray-400"
          />
        </button>

        <!-- Dropdown menu -->
        <div
          v-if="activeStatus"
          class="z-[9998] bg-white divide-y divide-gray-100 rounded-sm shadow w-44 absolute top-12"
        >
          <ul class="py-2 text-sm text-gray-700">
            <li>
              <a href="#" class="block px-4 py-2 hover:bg-gray-100"
                >Dashboard</a
              >
            </li>
            <li>
              <a href="#" class="block px-4 py-2 hover:bg-gray-100">Settings</a>
            </li>
            <li>
              <a href="#" class="block px-4 py-2 hover:bg-gray-100">Earnings</a>
            </li>
            <li>
              <a href="#" class="block px-4 py-2 hover:bg-gray-100">Sign out</a>
            </li>
          </ul>
        </div>
      </div>

      <div
        class="flex justify-center items-center w-10 border-gray-300 rounded-sm bg-[#066BAF] shadow-lg"
      >
        <font-awesome-icon
          icon="fa-xmark"
          class="h-[19px] w-[19px] text-white"
        />
      </div>
    </div>

    <div class="px-3 mt-4">
      <div id="hot"></div>
    </div>
  </NavBar>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import Handsontable from "handsontable";
import "handsontable/dist/handsontable.full.min.css";

import NavBar from "@/components/ui/NavBar.vue";

export default {
  name: "SalaryPage",
  components: { NavBar },
  setup() {
    const store = useStore();
    const activeStatus = ref(false);
    const hotInstance = ref(null);
    // const rowIndex = ref();
    const fixedData = ref([]);
    const loading = ref(false);

    const filter = () => {
      activeStatus.value = !activeStatus.value;
    };

    // const fixedData = ref([
    //   [
    //     "John Doe",
    //     "pn89090000",
    //     "Manager",
    //     "Type A",
    //     "Category X",
    //     "5000",
    //     "",
    //     "",
    //     "",
    //     "",
    //   ],
    //   [
    //     "Jane Smith",
    //     "pn89090000",
    //     "Developer",
    //     "Type B",
    //     "Category Y",
    //     "4000",
    //     "",
    //     "",
    //     "",
    //     "",
    //   ],
    // ]);

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.innerHTML = value;
      td.classList.add("htCenter", "htMiddle");
      td.style.color = "rgb(71 85 105)";
      td.style.background = "#CAEEC2";
    };

    const additionalRenderer = (instance, td, row, col, prop, value) => {
      td.innerHTML = value;
      td.classList.add("htCenter", "htMiddle");
      td.style.color = "rgb(71 85 105)";
      td.style.background = "#f8f8ba";
    };

    const suutgalRenderer = (instance, td, row, col, prop, value) => {
      td.innerHTML = value;
      td.classList.add("htCenter", "htMiddle");
      td.style.color = "rgb(71 85 105)";
      td.style.background = "#f8d1cd";
    };

    const tableSettings = ref({
      data: fixedData.value,
      licenseKey: "non-commercial-and-evaluation",
      colWidths: 140,
      rowHeaders: true,
      width: "100%",
      height: "auto",
      fixedColumnsStart: 1,
      manualColumnFreeze: true,
      rowHeights: 35,
      // contextMenu: true,
      colHeaders: [
        "Ажилчдын нэрс",
        "Регистрийн дугаар",
        "Албан тушаал",
        "Даатгуулагчийн</br> төрөл",
        "Ажил мэргэжлийн </br>ангилал",
        "Нийт үндсэн цаг",
        "Нийт илүү цаг",
        "Нийт өглөө, орой цаг",
        "Нийт шөнийн цаг",
        "Нийт бонус цаг",
        "Нийт ажилласан</br> өдөр",
        "Бодогдсон цалин",
        "МУЧ, ажлын</br> туршлагын нэмэгдэл",
        "Удирдан ЗБ ур</br> чадварын нэмэгдэл",
        "Ажилсан жилийн </br> нэмэгдэл",
        "Хоол, унааны </br> нэмэгдэл",
        "БО-н 1%",
        "Хүүхдийн мөнгө",
        "Амралтын цалин",
        "Урамшуулал, Шагнал,</br>Бонус цалин",
        "Бусад",
        "Татварын өмнөх </br>нийт цалин",
        "ЭМНДШ</br>/хувь хүн/",
        "ХХОАТ",
        "Урьдчилгаа цалин</br>/дансаар/",
        "БО-н 1% /дансаар/",
        "Шагнал, урамшуулал",
        "Урьдчилан олгосон</br> цалин, Э.амралт",
        "Бусад суутгал, </br>/бэлэн олгосон/",
        "Олгох сүүл цалин</br> /дансаар/ ",
      ],
      columns: [
        { data: "employee_name" },
        { data: "register_num" },
        { data: "position" },
        { data: "insurance_type" },
        { data: "job_categories" },
        { data: "1", renderer: commonRenderer },
        { data: "2", renderer: commonRenderer },
        { data: "3", renderer: commonRenderer },
        { data: "4", renderer: commonRenderer },
        { data: "5", renderer: commonRenderer },
        { data: "6", renderer: commonRenderer },
        { data: "7" },
        { data: "ajliin_turshlaga", renderer: additionalRenderer },
        { data: "ur_chadvar", renderer: additionalRenderer },
        { data: "ajillasan_jil", renderer: additionalRenderer },
        { data: "11", renderer: additionalRenderer },
        { data: "borluulalt_orlogo_huvi", renderer: additionalRenderer },
        { data: "huuhdiin_mongo", renderer: additionalRenderer },
        { data: "14", renderer: additionalRenderer },
        { data: "15", renderer: additionalRenderer },
        { data: "busad", renderer: additionalRenderer },
        { data: "17" },
        { data: "18", renderer: suutgalRenderer },
        { data: "19", renderer: suutgalRenderer },
        { data: "20" },
        { data: "21" },
        { data: "22" },
        { data: "23" },
        { data: "24" },
        { data: "25" },
      ],
    });

    onMounted(async () => {
      const container = document.getElementById("hot");
      if (container) {
        hotInstance.value = new Handsontable(container, tableSettings.value);
      }
    });

    const getEmployeesWithDetails = async () => {
      fixedData.value = await store.dispatch("fetchEmployeesWithDetails");
    };

    // Update the data in the table after initialization
    watch(fixedData, (newData) => {
      if (hotInstance.value) {
        hotInstance.value.loadData(newData);
      }
    });

    const saveSalaryData = async () => {
      try {
        // const newRowData = hotInstance.value.getDataAtRow(rowIndex.value);
        // if (!newRowData[0]) return alert("Ажилтны нэрийг оруулан уу?");

        // employeeData.name = newRowData[0];
        // employeeData.registerNum = newRowData[1];
        // employeeData.position = newRowData[2];
        // employeeData.insuranceType = newRowData[3];
        // employeeData.jobCategory = newRowData[4];
        // employeeData.currentExchangeRate = newRowData[5];
        // employeeData.workedTime = newRowData[6];
        // employeeData.salary = newRowData[7];
        // await store.dispatch("insertNewEmployee", employeeData);

        const allSalaryData = hotInstance.value.getData();
        console.log("submit");
        await store.dispatch("insertAndCountSalaray", allSalaryData);
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    return {
      filter,
      activeStatus,
      saveSalaryData,
      loading,
      getEmployeesWithDetails,
      fixedData,
      hotInstance,
    };
  },
};
</script>
