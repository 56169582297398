<template>
  <NavBar>
    <div class="flex justify-between items-center mt-3 mb-8">
      <div class="flex items-center gap-3">
        <select
          name="employee"
          id="employee"
          v-model="isValues.emoployeeId"
          class="font-medium rounded-sm px-5 h-10 flex justify-center items-center bg-gray-100 text-gray-400 border border-gray-200"
        >
          <option value="" disabled>Ажилтны нэр сонгох</option>
          <option
            v-for="(employee, index) in employeeData"
            :key="index"
            :value="employee.employee_id"
            class="text-black"
          >
            {{
              employee?.last_name?.substring(0, 1) + "." + employee?.first_name
            }}
          </option>
        </select>

        <select
          name="employee"
          id="employee"
          v-model="isValues.type"
          class="font-medium rounded-sm px-5 h-10 flex justify-center items-center bg-gray-100 text-gray-400 border border-gray-200"
        >
          <option value="" disabled>Нөхцөл сонгох</option>
          <option class="text-black">Урьдчилгаа цалин</option>
          <option class="text-black">Сүүл цалин</option>
        </select>

        <input
          id="startDate"
          type="date"
          v-model="isValues.startDate"
          class="font-medium rounded-sm px-5 h-10 flex justify-center items-center bg-gray-100 text-gray-400 border border-gray-200"
        />

        <input
          id="endDate"
          type="date"
          v-model="isValues.endDate"
          class="font-medium rounded-sm px-5 h-10 flex justify-center items-center bg-gray-100 text-gray-400 border border-gray-200"
        />

        <button
          class="flex justify-center items-center w-10 h-10 border-gray-300 rounded-sm bg-[#066BAF] shadow-lg opacity-50"
        >
          <!-- disabled -->
          <font-awesome-icon
            icon="fa-xmark"
            class="h-[19px] w-[19px] text-white"
          />
        </button>

        <!-- <div class="relative">
          <input
            v-model="inputValue"
            type="text"
            id="input"
            placeholder=" "
            class="peer h-10 w-full border-b-2 border-gray-300 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-500"
          />
          <label
            for="input"
            class="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:top-2.5 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-blue-500"
          >
            Enter text
          </label>
        </div> -->
      </div>

      <div class="flex items-center gap-3">
        <button
          class="bg-[#049758] text-[#CDC9C5] py-3 px-4 flex justify-center items-center gap-3 rounded-sm shadow-lg font-bold"
        >
          <font-awesome-icon icon="fa-download" class="h-4 w-4 text-gary-50" />
          <p>Татах</p>
        </button>
        <!-- <button
          class="bg-[#049758] text-[#CDC9C5] py-3 px-4 flex justify-center items-center gap-3 rounded-sm shadow-lg font-bold"
          @click="addRow"
        >
          <font-awesome-icon icon="fa-add" class="h-4 w-4 text-gary-50" />
          <p>Мөр нэмэх</p>
        </button> -->

        <button
          class="bg-[#049758] text-[#CDC9C5] py-3 px-4 flex justify-center items-center gap-3 rounded-sm shadow-lg font-bold"
          @click="saveHour"
        >
          <font-awesome-icon
            icon="fa-floppy-disk"
            class="h-4 w-4 text-gary-50"
          />
          <p>Хадгалах</p>
        </button>
      </div>
    </div>

    <div class="hot-container" v-if="fixedData.length > 0">
      <HotTable
        :data="fixedData"
        :settings="hotSettings"
        ref="hotRef"
        class="custom-handsontable"
      />
    </div>
  </NavBar>
</template>

<script>
import { ref, onMounted, watch, reactive } from "vue";
import { useStore } from "vuex";

import { HotTable } from "@handsontable/vue3";
// import Handsontable from "handsontable";
import ColumnSummary from "handsontable/plugins/columnSummary";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";

import NavBar from "@/components/ui/NavBar.vue";

registerAllModules(ColumnSummary);

export default {
  name: "TimeRegister",
  components: { NavBar, HotTable },
  setup() {
    const store = useStore();

    const hotRef = ref(null);

    const fixedData = ref([]);
    const employeeData = ref([]);

    const isValues = reactive({
      emoployeeId: "",
      type: "",
      startDate: "",
      endDate: "",
    });

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.innerHTML = value;
      td.classList.add("htCenter", "htMiddle");
      td.style.color = "rgb(71 85 105)";

      if (col == 1) {
        const dateValue = new Date(value);
        const dayOfWeek = dateValue.getDay();

        let symbolizedValue = value;

        if (dayOfWeek === 6) {
          symbolizedValue += " (Saturday)";
        } else if (dayOfWeek === 0) {
          symbolizedValue += " (Sunday)";
        }

        td.innerHTML = symbolizedValue;
      }
    };

    const handleTimeRenderer = (instance, td, row, col, prop, value) => {
      td.innerHTML = "";
      td.style.color = "rgb(71 85 105)";
      td.style.textAlign = "center";
      td.style.verticalAlign = "middle";

      let options;

      if (col === 2) {
        options = ["07:30", "08:00", "12:30", "19:30", "other"];
      } else if (col === 3) {
        options = ["07:30", "11:45", "16:11", "18:15", "19:30", "other"];
      }

      // Add custom value to options if it exists and is not already included
      if (value && !options.includes(value)) {
        options.splice(options.length - 1, 0, value); // Add before "other"
      }

      // Function to render the select dropdown
      const renderSelect = (selectedValue = value) => {
        const select = document.createElement("select");
        select.style.width = "100%";
        select.style.height = "auto";
        select.style.padding = "4px";
        select.style.border = "1px solid #ccc";
        select.style.boxSizing = "border-box";

        options.forEach((optionValue) => {
          const option = document.createElement("option");
          option.value = optionValue;
          option.text = optionValue;
          if (selectedValue === optionValue) {
            option.selected = true;
          }
          select.appendChild(option);
        });

        td.innerHTML = "";
        td.appendChild(select);

        // Event listener for selection change
        select.addEventListener("change", (event) => {
          if (event.target.value === "other") {
            renderTimeInput();
          } else {
            instance.setDataAtCell(row, col, event.target.value);
          }
        });
      };

      // Function to render the time input
      const renderTimeInput = () => {
        const input = document.createElement("input");
        input.type = "time";
        input.value = value || "";
        input.style.width = "100%";
        input.style.height = "auto";
        input.style.padding = "4px";
        input.style.border = "1px solid #ccc";
        input.style.boxSizing = "border-box";

        td.innerHTML = "";
        td.appendChild(input);

        // Set focus on the time input field
        input.focus();

        // Event listener for time input change
        input.addEventListener("change", (inputEvent) => {
          const newValue = inputEvent.target.value;

          // Add the new value to options if it isn't already there
          if (!options.includes(newValue)) {
            options.splice(options.length - 1, 0, newValue); // Add before "other"
          }

          // Set the selected time in the table
          instance.setDataAtCell(row, col, newValue);

          // Re-render the select dropdown with the new value selected
          renderSelect(newValue);
        });
      };

      // Initial render of the select dropdown
      renderSelect();
    };

    const totalTimeCountRenderer = (instance, td, row) => {
      const rowData = hotRef.value.hotInstance.getDataAtRow(row);

      let total = 0;
      for (let i = 4; i <= 9; i++) {
        total += parseFloat(rowData[i]) || 0;
      }

      td.innerHTML = total.toFixed(2);
      td.classList.add("htRight", "htMiddle");
    };

    // const calculateColumnTotals = () => {
    //   const instance = hotRef.value.hotInstance;
    //   const columnTotals = [];
    //   const rowCount = instance.countRows();
    //   const columnCount = instance.countCols();

    //   // Initialize totals array with zeros
    //   for (let i = 0; i < columnCount; i++) {
    //     columnTotals[i] = 0;
    //   }

    //   // Calculate totals for each column
    //   for (let row = 0; row < rowCount; row++) {
    //     for (let col = 0; col < columnCount; col++) {
    //       const cellValue = instance.getDataAtCell(row, col);
    //       const numericValue = parseFloat(cellValue);

    //       // Accumulate total only if the cell value is a number
    //       if (!isNaN(numericValue)) {
    //         columnTotals[col] += numericValue;
    //       }
    //     }
    //   }

    //   // Adjust the totalsRow
    //   return [
    //     "Нийт", // The label
    //     "", // Placeholder for merged columns
    //     "", // Placeholder for merged columns
    //     "", // Placeholder for merged columns
    //     "", // Placeholder for other columns
    //     columnTotals[5], // Example: specific total (7.6)
    //     columnTotals[9], // Example: specific total (0.4)
    //     columnTotals[11], // Example: specific total (8)
    //   ];
    // };

    const calculateColumnTotals = () => {
      const instance = hotRef.value.hotInstance;
      const columnTotals = [];
      const rowCount = instance.countRows();
      const columnCount = instance.countCols();

      // Initialize totals array with zeros
      for (let i = 0; i < columnCount; i++) {
        columnTotals[i] = 0;
      }

      // Calculate totals for each column
      for (let row = 0; row < rowCount; row++) {
        for (let col = 0; col < columnCount; col++) {
          const cellValue = instance.getDataAtCell(row, col);
          console.log("G1---->", typeof cellValue, cellValue);
          // const numericValue = parseFloat(cellValue) || 0;
          // columnTotals[col] += numericValue;
        }
      }

      // Add 'Total' label in the first column
      columnTotals[0] = "Нийт";
      return columnTotals;
    };

    // let isUpdating = false;

    const colHeaders = ref([
      "id",
      "Сар өдөр",
      "Эхэлсэн цаг",
      "Дууссан цаг",
      "Өвчтэй, чөлөө",
      "Үндсэн цаг",
      `илүү цаг`,
      "Өглөө, орой цаг",
      "Шөнийн цаг",
      "Цагийн бонус  </br> цаг",
      "Нийтээр амрах </br> баярын өдөр",
      "Нийт",
    ]);

    const nestedHeaders = ref([
      [
        "",
        { label: "Нэг цагийн үнэлгээ JPY", colspan: 3 },
        "100% - 438.47",
        "100% - 438.47",
        "150% - 657.71",
        "125% - 548.09",
        "150% - 657.71",
        "100% - 438.47",
        "200% - 876.94",
        "",
      ],

      colHeaders.value,
    ]);

    const hotSettings = {
      licenseKey: "non-commercial-and-evaluation",
      stretchH: "all",
      rowHeaders: true,
      rowHeights: 35,
      height: "auto",
      fixedColumnsStart: 1,
      manualColumnFreeze: true,
      hiddenColumns: {
        columns: [0],
        indicators: true,
      },
      nestedHeaders: nestedHeaders.value,
      columns: [
        { data: "id", readOnly: true },
        {
          data: "sar_odor",
          type: "date",
          dateFormat: "YYYY-MM-DD",
          renderer: commonRenderer,
        },
        { data: "ehelsen_tsag", readOnly: true, renderer: handleTimeRenderer },
        { data: "duussan_tsag", readOnly: true, renderer: handleTimeRenderer },
        {
          data: "ovchtei_cholootei",
          type: "numeric",
          renderer: commonRenderer,
        },
        { data: "tr_undsen_tsag", type: "numeric", renderer: commonRenderer },
        { data: "tr_iluu_tsag", type: "numeric", renderer: commonRenderer },
        {
          data: "tr_ogloo_oroi_tsag",
          type: "numeric",
          renderer: commonRenderer,
        },
        { data: "tr_shoniin_tsag", type: "numeric", renderer: commonRenderer },
        {
          data: "tsagiin_bonus_tsag",
          type: "numeric",
          renderer: commonRenderer,
        },
        {
          data: "niiteer_amrah_odor",
          type: "numeric",
          renderer: commonRenderer,
        },
        {
          data: "niit_tsag",
          type: "numeric",
          renderer: totalTimeCountRenderer,
        },
      ],
      // afterChange(source, changes) {
      //   const instance = hotRef.value.hotInstance;
      //   if (source === "loadData" || !changes) {
      //     return;
      //   }

      //   // Ensure there are no existing totals rows
      //   const rowCount = instance.countRows();
      //   const lastRowData = instance.getDataAtRowProp(rowCount - 1, 0);

      //   if (rowCount > 0 && lastRowData === "Нийт") {
      //     instance.alter("remove_row", rowCount - 1);
      //   }
      //   instance.alter("insert_row_below", rowCount);
      // },

      cells(row, col) {
        const cellProperties = {};
        const instance = hotRef.value.hotInstance;
        const totalsRow = calculateColumnTotals();

        if (!totalsRow) {
          return cellProperties;
        }

        if (row === instance.countRows() - 1) {
          // Last row
          if (col === 1) {
            cellProperties.className = "htCenter, htMiddle";
            cellProperties.renderer = (instance, td) => {
              td.innerHTML = "Нийт ажлын цаг";
              td.style.fontWeight = "bold";
              td.style.textAlign = "center";
            };
          } else if (col === 5) {
            cellProperties.renderer = (instance, td) => {
              td.innerHTML = totalsRow[5];
              td.style.textAlign = "right";
              td.style.fontWeight = "bold";
            };
          } else if (col === 9) {
            cellProperties.renderer = (instance, td) => {
              td.innerHTML = totalsRow[9];
              td.style.textAlign = "right";
              td.style.fontWeight = "bold";
            };
          } else if (col === 11) {
            cellProperties.renderer = (instance, td) => {
              td.innerHTML = totalsRow[11];
              td.style.textAlign = "right";
              td.style.fontWeight = "bold";
            };
          } else {
            cellProperties.renderer = (instance, td) => {
              td.innerHTML = "";
              td.style.textAlign = "right";
            };
          }
        }

        return cellProperties;
      },
    };

    // when add new row, add new value to the new row && fixed values
    // const addRow = () => {
    //   const hotInstance = hotRef.value.hotInstance;
    //   const newRow = hotInstance.countRows();

    //   hotInstance.alter("insert_row_below", newRow);
    //   hotInstance.setDataAtCell(newRow, 9, 0.4);
    //   hotInstance.setDataAtCell(newRow, 2, "07:30");
    //   hotInstance.setDataAtCell(newRow, 3, "16:04");
    //   hotInstance.setDataAtCell(newRow, 5, 7.6);
    // };

    watch(
      () => [
        isValues.emoployeeId,
        isValues.type,
        isValues.startDate,
        isValues.endDate,
      ],
      ([emoployeeId, type, startDate, endDate]) => {
        if (emoployeeId && type && startDate && endDate) {
          getWorkedTime(emoployeeId, type, startDate, endDate);
        }
      }
    );

    onMounted(async () => {
      employeeData.value = await store.dispatch("fetchEmployeesWithDetails");
    });

    // Update the data in the table after initialization
    watch(fixedData, (newData) => {
      if (hotRef.value && hotRef.value.hotInstance) {
        hotRef.value.hotInstance.loadData(newData);
      }
    });

    const saveHour = async () => {
      try {
        const employeesTimeRegister = hotRef.value.hotInstance.getData();
        const dataWithoutLastRow = employeesTimeRegister.slice(0, -1);

        const res = await store.dispatch("insertEmployeesTime", {
          id: isValues.emoployeeId,
          tableData: dataWithoutLastRow,
        });

        if (res.data.message == "successfully") {
          window.location.reload();
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const getWorkedTime = async (emoployeeId, type, startDate, endDate) => {
      if (emoployeeId && type && startDate && endDate) {
        const res = await store.dispatch("fetchWorkedHoursById", {
          id: emoployeeId,
          type: type,
          startDate: startDate,
          endDate: endDate,
        });

        if (res.length > 0) {
          fixedData.value = res;
        }
      }
    };

    return {
      saveHour,
      fixedData,
      isValues,
      employeeData,
      hotSettings,
      hotRef,
      // addRow,
    };
  },
};
</script>
