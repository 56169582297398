<template>
  <NavBar>
    <br />
    <div v-if="isLoading" class="flex flex-col items-center text-red-500">
      Loading ...
    </div>
    <div v-else class="flex flex-col items-center">
      <h1>Мэдээлэл оруулах</h1>
      <div id="input-journal"></div>
      <button
        @click="checkData()"
        class="save-btn text-[#e7e7e7] bg-[#008cba] hover:bg-sky-800 font-bold rounded-md px-1"
      >
        Save
      </button>
      <h1>5. Ерөнхий журнал</h1>
      <div id="journal"></div>
    </div>
  </NavBar>
</template>

<script>
import { ref, onBeforeMount, nextTick } from "vue";
import { useStore } from "vuex";
import Handsontable from "handsontable";
import "handsontable/dist/handsontable.full.min.css";

import NavBar from "@/components/ui/NavBar.vue";

// import axios from "axios";

export default {
  components: { NavBar },
  setup() {
    const store = useStore();
    const data = ref([]);
    // const inputData = ref();
    const dansnii_dugaar = ref();
    const dansnii_ner = ref();
    const debit_credit = ref(["D", "C"]);
    const insertTableRows = ref();
    // eslint-disable-next-line no-unused-vars
    const totalAmount = ref();
    const isLoading = ref(false);
    const hotInsertTable = ref();

    onBeforeMount(async () => {
      await fetchP5AndP12();
    });

    const hotSettings = ref({
      data: data.value,
      licenseKey: "non-commercial-and-evaluation",
      colWidths: [60, 90, 300, 80, 90, 230, 80, 80],
      columns: [
        {
          data: "journal_id",
          title: "Д/Д",
          renderer: function (instance, td, row, col, prop, value) {
            td.style.textAlign = "center";
            td.innerHTML = value;
            return td;
          },
        },
        {
          data: "transaction_date",
          title: "Огноо",
          renderer: function (instance, td, row, col, prop, value) {
            td.style.textAlign = "center";
            td.innerHTML = value;
            return td;
          },
        },
        { data: "transaction_description", title: "Гүйлгээний утга" },
        {
          data: "partner",
          title: "Харилцагч",
          renderer: function (instance, td, row, col, prop, value) {
            td.style.textAlign = "center";
            td.innerHTML = value;
            return td;
          },
        },
        {
          data: "account_id",
          title: "Дансны </br> код",
          renderer: function (instance, td, row, col, prop, value) {
            td.style.textAlign = "center";
            td.innerHTML = value;
            return td;
          },
        },
        { data: "account_name", title: "Дансны нэр" },
        {
          data: "debit_amount",
          title: "Дебит дүн",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
        {
          data: "credit_amount",
          title: "Кредит дүн",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
      ],
    });

    const hotSettingsInsertData = ref({
      readOnly: true,
      data: [[], []],
      renderer: "html",
      licenseKey: "non-commercial-and-evaluation",
      colHeaders: [
        "Д/Д",
        "Огноо",
        "Дансны код",
        "Дансны нэр",
        "Гүйлгээний утга",
        "Харилцагч",
        "Дебет дүн",
        "Кредит дүн",
        "МГТ-ын мөр",
      ],
      colWidths: [50, 110, 110, 110, 110, 110, 110, 110, 110, 80],
      columns: [
        {},
        {
          type: "date",
          dateFormat: "MM/DD/YYYY",
          correctFormat: true,
          defaultDate: "01/01/1900",
          datePickerConfig: {
            firstDay: 0,
            showWeekNumber: true,
            disableDayFn(date) {
              return date.getDay() === 0 || date.getDay() === 6;
            },
          },
        },
        {
          type: "dropdown",
          source: dansnii_dugaar,
        },
        {
          type: "dropdown",
          source: dansnii_ner,
        },
        {},
        {},
        {},
        {},
        {},
        {
          renderer: safeHtmlRenderer,
        },
      ],
    });

    // eslint-disable-next-line no-unused-vars
    function safeHtmlRenderer(
      // eslint-disable-next-line no-unused-vars
      instance,
      // eslint-disable-next-line no-unused-vars
      td,
      // eslint-disable-next-line no-unused-vars
      row,
      // eslint-disable-next-line no-unused-vars
      col,
      // eslint-disable-next-line no-unused-vars
      prop,
      // eslint-disable-next-line no-unused-vars
      value,
      // eslint-disable-next-line no-unused-vars
      cellProperties
    ) {
      // WARNING: Be sure you only allow certain HTML tags to avoid XSS threats.
      // Sanitize the "value" before passing it to the innerHTML property.
      td.innerHTML =
        '<button class="plus-btn text-[#e7e7e7] bg-[#008cba] hover:bg-sky-800 font-bold rounded-md px-1">+</button>   <button class="minus-btn text-[#e7e7e7] bg-[#008cba] hover:bg-sky-800 font-bold rounded-md px-1" >-</button>';
    }

    const fetchP5AndP12 = async () => {
      try {
        isLoading.value = true;

        await store.dispatch("fetchP5");
        data.value = store.getters.getP5;

        hotSettings.value.data = data.value;

        await store.dispatch("fetchP12");
        const p12 = store.getters.getP12;

        // await axios.get("http://localhost/backend/test");

        dansnii_dugaar.value = p12.map((item) => item.kod);
        dansnii_ner.value = p12.map((item) => item.dansnii_ner);

        hotSettingsInsertData.value.columns[2].source = dansnii_dugaar.value;
        hotSettingsInsertData.value.columns[3].source = dansnii_ner.value;
        hotSettingsInsertData.value.columns[4].source = debit_credit.value;

        isLoading.value = false;

        nextTick(() => {
          const container = document.getElementById("journal");
          if (!container) {
            return;
          }
          new Handsontable(container, hotSettings.value);
        });

        nextTick(() => {
          const container = document.getElementById("input-journal");
          if (!container) {
            return;
          }

          container.addEventListener("click", function (event) {
            const target = event.target;
            if (target.classList.contains("plus-btn")) {
              const row = target.getAttribute("data-row-index");
              hotInsertTable.value.alter("insert_row_below", row, 1);
              // } else if (target.classList.contains("save-btn")) {
              //   checkData(
              //     JSON.parse(JSON.stringify(hotInsertTable.value.getData()))
              //   );
            }
          });

          hotInsertTable.value = new Handsontable(
            container,
            hotSettingsInsertData.value
          );

          // hotInsertTable.value.updateSettings({
          //   // eslint-disable-next-line no-unused-vars
          //   cells(row, col) {
          //     const cellProperties = {};
          //     if (
          //       (row === 0 && col !== 2) ||
          //       col === 3 ||
          //       col === 4 ||
          //       col === 7
          //     ) {
          //       cellProperties.readOnly = false;
          //     }
          //     return cellProperties;
          //   },
          // });

          hotInsertTable.value.updateSettings({
            cells(row, col) {
              const cellProperties = {};
              if (
                (row === 0 && col !== 2) ||
                col === 3 ||
                col === 6 ||
                col === 7
              ) {
                cellProperties.readOnly = false;
              }
              return cellProperties;
            },
          });

          // eslint-disable-next-line no-unused-vars
          hotInsertTable.value.addHook(
            "afterChange",
            // eslint-disable-next-line no-unused-vars
            (changes, src) => {
              if (
                changes[0][0] === 0 &&
                changes[0][1] === 0 &&
                changes[0][3] !== undefined
              ) {
                for (let i = 1; i < hotInsertTable.value.countRows(); i++) {
                  hotInsertTable.value.setDataAtCell(i, 0, changes[0][3]);
                }
              }

              if (
                changes[0][0] === 0 &&
                changes[0][1] === 1 &&
                changes[0][3] !== undefined
              ) {
                for (let i = 1; i < hotInsertTable.value.countRows(); i++) {
                  hotInsertTable.value.setDataAtCell(i, 1, changes[0][3]);
                }
              }

              if (
                changes[0][0] === 0 &&
                changes[0][1] === 5 &&
                changes[0][3] !== undefined
              ) {
                for (let i = 1; i < hotInsertTable.value.countRows(); i++) {
                  hotInsertTable.value.setDataAtCell(i, 5, changes[0][3]);
                }
              }

              if (
                changes[0][0] === 0 &&
                changes[0][1] === 4 &&
                changes[0][3] !== undefined
              ) {
                for (let i = 1; i < hotInsertTable.value.countRows(); i++) {
                  hotInsertTable.value.setDataAtCell(i, 4, changes[0][3]);
                }
              }

              if (changes[0][1] === 3 && changes[0][3] !== undefined) {
                p12.forEach((item, index, p12) => {
                  if (p12[index].dansnii_ner === changes[0][3]) {
                    hotInsertTable.value.setDataAtCell(
                      changes[0][0],
                      2,
                      p12[index].kod
                    );
                  }
                });
              }
            }
          );
        });
      } catch (error) {
        console.error("Error fetching category and user's name:", error);
      }
    };

    // eslint-disable-next-line no-unused-vars
    const insertLog = async (dataP5) => {
      try {
        const res = await store.dispatch("insertNewJournal", dataP5);
        if (res.status == 200) window.location.reload();
      } catch (e) {
        console.log(e);
      }
    };

    // const checkData = async () => {
    //   const data = JSON.parse(JSON.stringify(hotInsertTable.value.getData()));

    //   console.log("received data----->", data);
    //   let isCheck = false;
    //   let isDebit = false;
    //   let isCredit = false;
    //   let totalDebit = 0;
    //   let totalCredit = 0;
    //   // let inputNotification = [];
    //   // eslint-disable-next-line no-unused-vars
    //   data.forEach((data, i) => {
    //     // if (data[4] == "D") {
    //     //   isDebit = true;
    //     //   totalDebit = totalDebit + parseInt(data[7]);
    //     // }
    //     // if (data[4] == "C") {
    //     //   isCredit = true;
    //     //   totalCredit = totalCredit + parseInt(data[7]);
    //     // }

    //     if (data[0]) {
    //       if (data[1]) {
    //         if (data[3]) {
    //           if (data[4]) {
    //             if (data[5]) {
    //               if (data[6]) {
    //                 if (data[7]) {
    //                   if (isDebit || isCredit) {
    //                     isCheck = true;
    //                   } else {
    //                     alert(i + 1 + "r mur deer Debit,Credit songono uu");
    //                   }
    //                 } else {
    //                   alert(i + 1 + "r mur deer Dun oruulna uu");
    //                 }
    //               } else {
    //                 alert(i + 1 + "r mur deer Guilgeenii utga oruulna uu");
    //               }
    //             } else {
    //               alert(i + 1 + "r mur deer Hariltsagch oruulna uu");
    //             }
    //           } else {
    //             alert(i + 1 + "r mur deer Debit, Credit songono uu");
    //           }
    //         } else {
    //           alert(i + 1 + "r mur deer Dansnii ner oruulna uu");
    //         }
    //       } else {
    //         alert(i + 1 + "r mur deer Ognoo oruulna uu");
    //       }
    //     } else {
    //       alert(i + 1 + "r mur deer D/D dugaar oruulna uu");
    //       // emptyCell = true;
    //       //davtaltaas garah command bichih
    //       // if (data[4] == "D") {
    //       //   isDebit = true;
    //       //   console.log("D:", data[7]);
    //       //   totalDebit = totalDebit + parseInt(data[7]);
    //       // }
    //       // if (data[4] == "C") {
    //       //   isCredit = true;
    //       //   console.log("C:", data[7]);
    //       //   totalCredit = totalCredit + parseInt(data[7]);
    //       // }
    //     }
    //   });

    //   if (isCheck) {
    //     if (isCredit && isDebit) {
    //       if (totalCredit == totalDebit) {
    //         insertLog(data);
    //         alert("Амжилттай хадгаллаа");
    //       } else {
    //         alert("Debit, Credit tei hargalzah toon dun tohirsongui!!!");
    //       }
    //     } else {
    //       alert("Debit, Credit tohirsongui !!!");
    //     }
    //   }

    //   console.log("isCredit: ", isCredit, " isDebit:", isDebit);
    //   console.log("totalDebit: ", totalDebit, " totalCredit: ", totalCredit);
    // };

    const checkData = async () => {
      const data = JSON.parse(JSON.stringify(hotInsertTable.value.getData()));

      insertLog(data);
    };

    return {
      insertTableRows,
      fetchP5AndP12,
      // data,
      isLoading,
      checkData,
      // hotSettingsInsertData,
      // tableRow,
      // isEqual,
      // data,
      // hotRef,
      // hotSettingsTable5,
      // insertLog,
      // dansnii_dugaar,
      // dansnii_ner,
    };
  },
};
// eslint-disable-next-line no-unused-vars
</script>
