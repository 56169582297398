<template>
  <NavBar>
    <br />
    <div class="flex flex-col items-center text-red-500" v-if="!data">
      <h1>Мэдээлэл олдсонгүй!!!</h1>
    </div>

    <!-- <div class="flex flex-col items-center">
      <h1>6. Гүйлгээ баланс</h1>
      <div
        class="flex justify-center items-center gap-8 my-4 text-gray-600 text-sm font-bold"
      >
        <button
          @click="showSection('list')"
          class="bg-blue-100 p-2 rounded-lg w-32"
        >
          Жагсаалт
        </button>
        <button
          @click="showSection('graphic')"
          class="bg-blue-100 p-2 rounded-lg w-32"
        >
          График
        </button>
      </div>

      <div id="test" v-if="activeSection === 'list'"></div>

      <div v-if="activeSection === 'graphic'" class="flex mt-10">
        <chart-com
          v-for="(chart, index) in charts"
          :key="index"
          :data="chart"
          :width="chart.height"
          :height="chart.height"
        >
        </chart-com>
      </div>
    </div> -->

    <div class="flex flex-col items-center">
      <div class="flex gap-10 text-sm my-3">
        <div class="flex gap-3 items-center">
          <p>Жил:</p>
          <select name="uliral" id="uliral" class="p-1 rounded-sm w-24">
            <option value="1">2023</option>
            <option value="2">2024</option>
            <option value="3">2026</option>
            <option value="4">2027</option>
          </select>
        </div>
        <div class="flex gap-3 items-center">
          <p>Улирал:</p>
          <select name="uliral" id="uliral" class="p-1 rounded-sm w-24">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </select>
        </div>
        <div
          class="flex justify-center items-center gap-8 my-4 text-gray-600 text-sm font-bold"
        >
          <button
            @click="showSection('list')"
            class="bg-blue-100 p-2 rounded-lg w-32"
          >
            Жагсаалт
          </button>
          <button
            @click="showSection('graphic')"
            class="bg-blue-100 p-2 rounded-lg w-32"
          >
            График
          </button>
        </div>
      </div>

      <div class="bg-white w-[1100px] flex flex-col items-center py-4">
        <h1 class="font-bold text-lg uppercase">Гүйлгээ баланс</h1>
        <div class="flex justify-between w-[1060px] my-3">
          <div>
            <p class="font-bold border-b-2 border-gray-600 p-1">Саруул ХХК</p>
            <p class="p-1">(Аж ахуйн нэгжийн нэр )</p>
          </div>
          <div class="flex items-center gap-3">
            <p>Тайлант үе:</p>
            <p>2023.01.01 - 2023.12.31 /төгрөгөөр/</p>
          </div>
        </div>

        <div id="test" v-if="activeSection === 'list'"></div>

        <div v-if="activeSection === 'graphic'" class="flex mt-10">
          <chart-com
            v-for="(chart, index) in charts"
            :key="index"
            :data="chart"
            :width="chart.height"
            :height="chart.height"
          >
          </chart-com>
        </div>
      </div>
    </div>
  </NavBar>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import Handsontable from "handsontable";

import ChartCom from "../components/ChartCom.vue";
import NavBar from "@/components/ui/NavBar.vue";

export default {
  name: "GuilbalA",
  components: { ChartCom, NavBar },
  setup() {
    const store = useStore();
    const data = ref();
    const activeSection = ref("list");

    const charts = ref([{}, {}]);

    const showSection = (section) => {
      activeSection.value = section;
    };

    const initializeHandsontable = () => {
      const test = document.getElementById("test");
      if (!test) {
        console.error("test element not found");
        return;
      }
      new Handsontable(test, hotSettings);
    };

    const hotSettings = {
      licenseKey: "non-commercial-and-evaluation",
      colWidths: [80, 200, 130, 130, 130, 130, 130, 130],
      colHeaders: [
        "Код",
        "Дансны нэр",
        "Эхний үлдэгдэл </br>(Debit)",
        "Эхний үлдэгдэл </br>(Credit)",
        "Гүйлгээ </br>(Debit)",
        "Гүйлгээ </br>(Credit)",
        "Эцсийн үлдэгдэл </br>(Debit)",
        "Эцсийн үлдэгдэл </br>(Credit)",
      ],
      columns: [
        { data: "account_id" },
        { data: "account_name" },
        {
          data: "debit_initial_balance",
          renderer: function (instance, td, row, col, prop, value) {
            td.style.textAlign = "right";
            td.classList.add("htCenter", "htMiddle");
            td.style.background = "#F8F9FB";
            td.style.color = "rgb(71 85 105)";
            if (typeof value === "number") {
              if (value < 0) {
                td.innerHTML = `(${Math.abs(value).toLocaleString()})`;
              } else if (value === 0) {
                td.innerHTML = "";
              } else {
                td.innerHTML = value.toLocaleString();
              }
            } else {
              td.innerHTML = value;
            }
            return td;
          },
        },
        {
          data: "credit_initial_balance",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
        {
          data: "total_debits",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
        {
          data: "total_credits",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
        {
          data: "debit_final_balance",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
        {
          data: "credit_final_balance",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
      ],
    };

    onMounted(async () => {
      try {
        data.value = await store.dispatch("fetchAllTransaction");
        hotSettings.data = data.value.data;

        charts.value[0] = debtChart();
        charts.value[1] = creditChart();

        initializeHandsontable();
      } catch (error) {
        return error;
      }
    });

    const debtChart = () => {
      const sum = data.value.debit_chart?.reduce((acc, item) => {
        const value = item.debit_final_balance;
        if (!isNaN(value)) {
          return acc + value;
        } else {
          return acc;
        }
      }, 0);
      return {
        type: "doughnut",
        data: {
          labels: data.value.debit_chart?.map((item) => item.account_name),
          datasets: [
            {
              data: data.value.debit_chart?.map(
                (item) => item.debit_final_balance
              ),
              backgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#E7E9ED",
                "#4BC0C0",
                "#F7464A",
                "#46BFBD",
                "#FDB45C",
                "#949FB1",
                "#4D5360",
              ],
              borderWidth: 0,
            },
          ],
        },
        options: {
          indexAxis: "x",
          scaleShowValues: true,
          responsive: false,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: "Дебет данс - " + sum,
              font: {
                size: 16,
              },
            },
            legend: {
              position: "bottom",
              display: true,
            },
          },
        },
      };
    };

    const creditChart = () => {
      const sum = data.value.credit_chart?.reduce((acc, item) => {
        const value = item.credit_final_balance;
        if (!isNaN(value)) {
          return acc + value;
        } else {
          return acc;
        }
      }, 0);

      return {
        type: "doughnut",
        data: {
          labels: data.value.credit_chart?.map((item) => item.account_name),
          datasets: [
            {
              data: data.value.credit_chart?.map(
                (item) => item.credit_final_balance
              ),
              backgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#E7E9ED",
                "#4BC0C0",
                "#F7464A",
                "#46BFBD",
                "#FDB45C",
                "#949FB1",
                "#4D5360",
              ],
              borderWidth: 0,
            },
          ],
        },
        options: {
          indexAxis: "y",
          scaleShowValues: true,
          responsive: false,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: "Кредит данс -" + sum,
              font: {
                size: 16,
              },
            },
            legend: {
              position: "bottom",
              display: true,
            },
          },
        },
      };
    };

    return {
      data,
      showSection,
      activeSection,
      charts,
      hotSettings,
    };
  },
};
</script>
