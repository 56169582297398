<template>
  <div class="flex items-center justify-center h-screen space-x-4 bg-gray-200">
    <button
      @click="navigateTo('/bowsys/login')"
      class="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg shadow-lg hover:bg-blue-600 transition-colors duration-300"
    >
      Bowsys
    </button>
    <button
      @click="navigateTo('/monshibasaki/login')"
      class="bg-green-500 text-white font-bold py-2 px-4 rounded-lg shadow-lg hover:bg-green-600 transition-colors duration-300"
    >
      Monshibasaki
    </button>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();

    const navigateTo = (path) => {
      router.push(path);
    };

    return {
      navigateTo,
    };
  },
};
</script>
