<template>
  <BowsysNavbar>
    <SpinnerCom v-if="isLoading" class="text-red-500 flex justify-center items-center min-h-svh" />

    <div v-else>
      <div class="flex flex-col justify-center gap-1 text-gray-500">
        <label for="month" class="font-bold">Сар сонгох</label>
        <input
          type="month"
          id="start"
          name="start"
          min="2024-07"
          v-model="date"
          @input="updateDate($event.target.value)"
          class="border py-2 px-4 gap-3 rounded-sm w-40"
        />
      </div>

      <h1 class="text-base my-4 font-bold">
        Нийт
        <span>({{ data?.list?.length }})</span>
      </h1>

      <div>
        <hot-table v-if="data?.list?.length > 0" :data="data.list" :settings="hotSettings" ref="hotRef" class="custom mt-6"></hot-table>
        <p v-else class="text-center font-bold my-4 text-red-500">Хоосон байна.</p>
      </div>
    </div>
  </BowsysNavbar>
</template>

<script>
  import { ref, reactive, onMounted, watch, computed } from 'vue'
  import { useStore } from 'vuex'

  import { HotTable } from '@handsontable/vue3'
  import { registerAllModules } from 'handsontable/registry'
  import 'handsontable/dist/handsontable.full.min.css'
  import 'handsontable/dist/handsontable.full.css'

  import BowsysNavbar from '@/components/bowsys/BowsysNavbar.vue'
  import SpinnerCom from '@/components/ui/SpinnerCom.vue'

  registerAllModules()
  export default {
    name: 'StatisticsPage',
    components: { BowsysNavbar, SpinnerCom, HotTable },
    setup() {
      const store = useStore()

      const isLoading = ref(false)
      const hotRef = ref(null)

      /*
       **************************************** GET STATISTICS DATA ****************************************
       */

      const message = reactive({
        text: '',
        isVisible: false,
      })

      const getCurrentYearMonth = () => {
        const currentDate = new Date()
        const year = currentDate.getFullYear()
        const month = String(currentDate.getMonth() + 1).padStart(2, '0')
        return `${year}-${month}`
      }

      const date = ref(getCurrentYearMonth())

      const commonRenderer = (instance, td, row, col, prop, value) => {
        td.innerHTML = value
        td.classList.add('htCenter', 'htMiddle')
        td.style.color = '#828282'

        const columnsToFormat = [4, 5, 6, 7, 8, 9]
        if (columnsToFormat.includes(col)) {
          const formattedValue = parseInt(value).toLocaleString()
          td.innerHTML = formattedValue
        }
      }

      const calculateColumnTotals = () => {
        const instance = hotRef.value.hotInstance
        const columnTotals = []
        const rowCount = instance.countRows()
        const columnCount = instance.countCols()

        for (let i = 0; i < columnCount; i++) {
          columnTotals[i] = 0
        }

        for (let row = 0; row < rowCount; row++) {
          for (let col = 3; col <= columnCount; col++) {
            const cellValue = instance.getDataAtCell(row, col)
            const numericValue = parseFloat(cellValue) || 0
            columnTotals[col] += numericValue
          }
        }
        columnTotals[0] = 'Нийт'
        return columnTotals
      }

      const hotSettings = {
        licenseKey: 'non-commercial-and-evaluation',
        stretchH: 'all',
        height: 'auto',
        rowHeights: 35,
        hiddenColumns: {
          columns: [3],
          indicators: true,
        },
        nestedHeaders: [
          [
            { label: 'Д/д', rowspan: 2 },
            { label: 'Огноо', rowspan: 2 },
            { label: 'Ажилтны овог нэр', rowspan: 2 },
            { label: 'Регистрийн дугаар', rowspan: 2 },
            { label: 'Тогтмол цалин', rowspan: 2 },
            { label: 'Ажилтны төлөх НД', colspan: 2 },
            { label: 'АО -ын төлөх НД', colspan: 2 },
            { label: 'Нийт НДШ', rowspan: 2 },
          ],
          ['', '', '', '', '', 'ЭД', 'НД', 'ЭД', 'НД', ''],
        ],
        columns: [
          { data: 'employee_id', readOnly: true, renderer: commonRenderer },
          { data: 'formatted_date', readOnly: true, renderer: commonRenderer },
          { data: 'employee_name', readOnly: true, renderer: commonRenderer },
          { data: 'register_num', readOnly: true, renderer: commonRenderer },
          { data: 'basic_salary', readOnly: true, renderer: commonRenderer },
          { data: 'eruul_mend_daatgal_huvi', readOnly: true, renderer: commonRenderer },
          { data: 'niigmiin_daatgal_huvi', readOnly: true, renderer: commonRenderer },
          { data: 'eruul_mend_daatgal_ao', readOnly: true, renderer: commonRenderer },
          { data: 'niigmiin_daatgal_ao', readOnly: true, renderer: commonRenderer },
          { data: 'total_niigmiin_daatgal', readOnly: true, renderer: commonRenderer },
        ],
        cells(row, col) {
          const cellProperties = {}
          const instance = hotRef.value.hotInstance
          const totalsRow = calculateColumnTotals()

          if (!totalsRow) {
            return cellProperties
          }

          const lastRow = instance.countRows() - 1

          if (row === lastRow) {
            switch (col) {
              case 2:
                cellProperties.className = 'htCenter htMiddle'
                cellProperties.renderer = (instance, td) => {
                  td.innerHTML = 'Нийт'
                  td.style.fontWeight = 'bold'
                  td.style.textAlign = 'center'
                }
                break
              case 4:
              case 5:
              case 6:
              case 7:
              case 8:
              case 9:
                cellProperties.renderer = (instance, td) => {
                  const formattedValue = parseInt(totalsRow[col]).toLocaleString()
                  td.innerHTML = formattedValue
                  td.style.textAlign = 'center'
                  td.style.fontWeight = 'bold'
                }
                break
            }
          }

          return cellProperties
        },
        colWidths: (index) => ([0, 1].includes(index) ? 50 : 120),
      }

      const data = computed(() => store.getters['bowsys/getStatisticList'])

      onMounted(async () => {
        try {
          isLoading.value = true
          const message = await store.dispatch('bowsys/fetchStatistics', { date: date.value })

          if (message === 'successfully') isLoading.value = false
        } catch (error) {
          console.error(error)
          throw error
        }
      })

      watch(data, (newVal, oldVal) => {
        if (newVal !== oldVal && hotRef.value) {
          hotRef.value.hotInstance.loadData(newVal.list)
        }
      })

      const updateDate = async (value) => {
        try {
          await store.dispatch('bowsys/fetchStatistics', { date: value })
        } catch (error) {
          console.error(error)
          throw error
        }
      }

      return { isLoading, hotRef, message, hotSettings, date, updateDate, data }
    },
  }
</script>

<style>
  .custom .ht_clone_top th {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
    background-color: #f0f0f0 !important;
    color: #808080 !important;
  }

  .custom .ht_master .wtHolder .wtHider .wtSpreader table td,
  .custom .ht_master .wtHolder .wtHider .wtSpreader table th {
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-top: none !important;
  }

  .custom .ht_master .wtHolder .wtHider .wtSpreader .htBorders div .wtBorder {
    background-color: inherit !important;
  }

  .custom .ht_master .wtHolder .wtHider .wtSpreader table td {
    border-top: 1px solid #f3f4f5 !important;
  }
</style>
