<template>
  <NavBar>
    <p class="flex flex-col items-center">Bowsys Home page</p>
  </NavBar>
</template>

<script>
import NavBar from "@/components/bowsys/BowsysNavbar";

export default {
  components: { NavBar },
};
</script>
