<template>
  <BowsysNavbar>
    <SpinnerCom v-if="isLoading" class="text-red-500 flex justify-center items-center min-h-svh" />

    <div v-else>
      <button
        class="bg-[#049758] hover:bg-green-700 text-[#CDC9C5] py-3 px-4 flex justify-center items-center gap-3 rounded-sm shadow-lg font-bold"
        @click="handleSaveBtn()"
      >
        <font-awesome-icon icon="fa-add" class="h-4 w-4 text-gary-50" />
        <p>Ажилтан нэмэх</p>
      </button>

      <ModalCom :isOpen="isModalOpen" :title="title" @close="isModalOpen = false" @save="saveNewAccount" :message="message">
        <div class="grid grid-cols-2 gap-3">
          <!-- 1 Input with Label -->
          <div class="flex flex-col gap-1">
            <label for="account-code" class="text-grayFont ml-1">Овог</label>
            <input type="text" id="account-code" class="border p-1.5 rounded-sm" v-model="isValue.lastName" />
          </div>

          <!-- 2 Input with Label -->
          <div class="flex flex-col gap-1">
            <label for="account-name" class="text-grayFont ml-1">Нэр</label>
            <input type="text" id="account-name" class="border p-1.5 rounded-sm" v-model="isValue.firstName" />
          </div>

          <!-- 3 Input with Label -->
          <div class="flex flex-col gap-1">
            <label for="account-code" class="text-grayFont ml-1">Регистрийн дугаар</label>
            <input type="text" id="account-code" class="border p-1.5 rounded-sm" v-model="isValue.registerNumber" />
          </div>

          <!-- 4 Input with Label -->
          <div class="flex flex-col gap-1">
            <label for="account-name" class="text-grayFont ml-1">Албан тушаал</label>
            <input type="text" id="account-name" class="border p-1.5 rounded-sm" v-model="isValue.position" />
          </div>

          <!-- 5 Input with Label -->
          <div class="flex flex-col gap-1">
            <label for="account-name" class="text-grayFont ml-1">Мэргэжил</label>
            <input type="text" id="account-name" class="border p-1.5 rounded-sm" v-model="isValue.major" />
          </div>

          <!-- 6 Input with Label -->
          <div class="flex flex-col gap-1">
            <label for="account-status" class="text-grayFont ml-1">Даатгуулагчийн төрөл</label>
            <select id="account-status" class="border p-1.5 rounded-sm" v-model="isValue.insuranceType">
              <option disabled selected>Төрөл сонгох</option>
              <option value="Энгийн ажилтан">Энгийн ажилтан</option>
              <option value="Давхар даатгуулагч">Давхар даатгуулагч</option>
              <option value="Жирэмсний декрет">Жирэмсний декрет</option>
              <option value="Тэтгэвэрт гарсан">Тэтгэвэрт гарсан</option>
            </select>
          </div>

          <!-- 7 Input with Label -->
          <div class="flex flex-col gap-1">
            <label for="account-name" class="text-grayFont ml-1">Үндсэн цалин</label>
            <input type="text" id="account-name" class="border p-1.5 rounded-sm" v-model="formattedSalary" />
          </div>
        </div>
      </ModalCom>

      <h1 class="text-base my-4 font-bold">
        Нийт
        <span>({{ list?.data?.length }})</span>
      </h1>

      <div>
        <hot-table v-if="list?.data?.length > 0" :data="list.data" :settings="hotSettings" ref="hotRef" class="custom-table mt-6"></hot-table>
        <p v-else class="text-center font-bold my-4 text-red-500">Хоосон байна.</p>
      </div>
    </div>
  </BowsysNavbar>
</template>

<script>
  import { ref, reactive, onMounted, watch, computed } from 'vue'
  import { useStore } from 'vuex'

  import { HotTable } from '@handsontable/vue3'
  import { registerAllModules } from 'handsontable/registry'
  import 'handsontable/dist/handsontable.full.min.css'
  import 'handsontable/dist/handsontable.full.css'

  import BowsysNavbar from '@/components/bowsys/BowsysNavbar.vue'
  import SpinnerCom from '@/components/ui/SpinnerCom.vue'
  import ModalCom from '@/components/bowsys/ModalCom.vue'

  registerAllModules()

  export default {
    name: 'EmployeePage',
    components: { BowsysNavbar, HotTable, SpinnerCom, ModalCom },
    setup() {
      const store = useStore()

      const isLoading = ref(false)
      const list = ref([])
      const hotRef = ref(null)

      const isModalOpen = ref(false)
      const title = ref('Шинэ ажилтан нэмэх')
      const rowId = ref(null)

      /*
       **************************************** ADD NEW EMPLOYEE ****************************************
       */

      const message = reactive({
        text: '',
        isVisible: false,
      })

      const isValue = reactive({
        lastName: '',
        firstName: '',
        registerNumber: '',
        position: '',
        major: '',
        insuranceType: '',
        basicSalary: '',
      })

      const formattedSalary = computed({
        get() {
          return isValue.basicSalary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        },
        set(value) {
          isValue.basicSalary = value.replace(/,/g, '')
        },
      })

      const resetValues = () => {
        Object.assign(isValue, {
          lastName: '',
          firstName: '',
          registerNumber: '',
          position: '',
          major: '',
          insuranceType: '',
          basicSalary: '',
        })
      }

      const handleSaveBtn = () => {
        isModalOpen.value = true
        title.value = 'Шинэ ажилтан нэмэх'
        resetValues()
      }

      const saveNewAccount = async () => {
        if (title.value == 'Ажилтны мэдээлэл засах') {
          await insertUpdateDeleteEmployee({ ...isValue, id: rowId.value, type: 'update' })
        } else {
          await insertUpdateDeleteEmployee(isValue)
        }
      }

      /*
       **************************************** SHOW EMPLOYEE LIST ****************************************
       */

      const commonRenderer = (instance, td, row, col, prop, value) => {
        td.innerHTML = value
        if (col == 7) {
          const formattedValue = parseFloat(value).toLocaleString()
          td.innerHTML = formattedValue
        }

        if (col === 8) {
          td.innerHTML = `<div class="flex items-center justify-center gap-5">
            <button class="update"><img src="https://cdn.pixabay.com/photo/2016/03/31/14/37/check-mark-1292787_1280.png" alt="" class="h-7 w-7 object-fit p-1 rounded-lg"/></button>
            <button class="delete"><img src="https://cdn.pixabay.com/photo/2014/03/25/15/19/cross-296507_1280.png" alt="" class="h-7 w-7 object-fit p-1 rounded-lg"/></button>
          </div>`
          const deleteButton = td.querySelector('.delete')
          const updateButton = td.querySelector('.update')
          const rowData = instance.getDataAtRow(row)

          if (deleteButton) {
            deleteButton.addEventListener('click', async () => {
              const confirmation = confirm(`Та ${rowData[0]} дугаартай ажилтны мэдээллийг устгахдаа итгэлтэй байна уу?`)
              if (confirmation) {
                await insertUpdateDeleteEmployee({ id: rowData[0] })
              }
            })
          }

          if (updateButton) {
            updateButton.addEventListener('click', async () => {
              isModalOpen.value = true
              title.value = 'Ажилтны мэдээлэл засах'

              rowId.value = rowData[0]
              isValue.lastName = rowData[1]
              isValue.firstName = rowData[2]
              isValue.registerNumber = rowData[3]
              isValue.position = rowData[4]
              isValue.major = rowData[5]
              isValue.insuranceType = rowData[6]
              isValue.basicSalary = rowData[7]
            })
          }
        }

        td.style.color = '#828282'
        td.classList.add('htCenter', 'htMiddle')
      }

      const hotSettings = {
        data: list.value && list.value.length > 0 ? list.value : [{}],
        licenseKey: 'non-commercial-and-evaluation',
        stretchH: 'all',
        height: 'auto',
        rowHeights: 35,
        columns: [
          { title: 'Д/д', data: 'id', readOnly: true, renderer: commonRenderer },
          { title: 'Ажилтны овог', data: 'last_name', readOnly: true, renderer: commonRenderer },
          { title: 'Ажилтны нэр', data: 'first_name', readOnly: true, renderer: commonRenderer },
          { title: 'Регистрийн дугаар', data: 'register_num', readOnly: true, renderer: commonRenderer },
          { title: 'Албан тушаал', data: 'position', readOnly: true, renderer: commonRenderer },
          { title: 'Мэргэжил', data: 'major', readOnly: true, renderer: commonRenderer },
          { title: 'Даатгуулагчийн төрөл', data: 'insurance_type', readOnly: true, renderer: commonRenderer },
          { title: 'Үндсэн цалин', data: 'basic_salary', readOnly: true, renderer: commonRenderer },
          { title: 'Засах / Устгах', data: 'button', readOnly: true, renderer: commonRenderer },
        ],
        colWidths: (index) => ([0].includes(index) ? 55 : 120),
      }

      onMounted(async () => {
        try {
          isLoading.value = true
          list.value = await store.dispatch('bowsys/fetchEmployeeList')
          if (list.value && list.value.message === 'successfully') {
            isLoading.value = false
          }
        } catch (error) {
          console.error(error)
          throw error
        }
      })

      watch(list, (newVal, oldVal) => {
        if (newVal !== oldVal && hotRef.value) {
          hotRef.value.hotInstance.loadData(newVal)
        }
      })

      const insertUpdateDeleteEmployee = async (data) => {
        try {
          const res = await store.dispatch('bowsys/insertUpdateDeleteEmployee', data)
          if (res.message) {
            message.text = res.message
            message.isVisible = true

            list.value = await store.dispatch('bowsys/fetchEmployeeList')

            setTimeout(() => {
              message.isVisible = false
            }, 3000)
          }
        } catch (error) {
          console.error(error)
          throw error
        }
      }

      return {
        hotRef,
        hotSettings,
        list,
        isLoading,
        isModalOpen,
        message,
        isValue,
        title,
        handleSaveBtn,
        saveNewAccount,
        formattedSalary,
      }
    },
  }
</script>

<style>
  .custom-table .ht_clone_top th {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    background-color: #fcfcfc !important;
    color: #828282 !important;
  }

  .custom-table .ht_master .wtHolder .wtHider .wtSpreader table td,
  .custom-table .ht_master .wtHolder .wtHider .wtSpreader table th {
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-top: none !important;
  }

  .custom-table .ht_master .wtHolder .wtHider .wtSpreader table td {
    border-top: 1px solid #f3f4f5 !important;
  }
</style>
