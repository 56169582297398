import { createRouter, createWebHistory } from 'vue-router'
import Cookies from 'js-cookie'

import HomePage from './pages/HomePage.vue'
import TsalinHuls from './pages/1-TsalinHuls.vue'
import BaraaMaterial from './pages/2-BaraaMaterial.vue'
import undsenHurungu from './pages/3-undsenHurungu.vue'
import SheetA from './pages/4-SheetA.vue'
import JournalA from './pages/5-JournalA.vue'
import GuilbalA from './pages/6-GuilbalA.vue'
import Hynalt from './pages/7-HynaltHu.vue'
import Ct_1 from './pages/8-Ct_1.vue'
import Ct_2 from './pages/9-Ct_2.vue'
import Ct_3 from './pages/10-Ct_3.vue'
import Ct_4 from './pages/11-Ct_4.vue'
import DansHutlult from './pages/12-DansHutlult.vue'
import LoginPage from './pages/LoginPage.vue'
import TaxStatement from './pages/TaxStatement.vue'

import EmployeePage from './pages/salary/EmployeePage.vue'
import SalaryPage from './pages/salary/SalaryPage.vue'
import TimeRegister from './pages/salary/TimeRegister.vue'
import ConstValues from './pages/salary/ConstValues.vue'

// ******************* BOWSYS NYABO ROUTES  *******************
import bowsysHome from './pages/bowsys-nyabo/HomePage.vue'
import bowsysLogin from './pages/bowsys-nyabo/LoginPage.vue'
import BalancePage from './pages/bowsys-nyabo/BalancePage.vue'
import GeneralJournal from './pages/bowsys-nyabo/GeneralJournal.vue'
import FixedAssets from './pages/bowsys-nyabo/FixedAssets.vue'
import ChartOfAccounts from './pages/bowsys-nyabo/ChartOfAccounts.vue'
import GoodsPage from './pages/bowsys-nyabo/inventory/GoodsPage.vue'

// 4 statements
import BalanceSheet from './pages/bowsys-nyabo/statements/BalanceSheet.vue'
import IncomeStatement from './pages/bowsys-nyabo/statements/IncomeStatement.vue'
import CashFlow from './pages/bowsys-nyabo/statements/CashFlow.vue'
import OwnerEquity from './pages/bowsys-nyabo/statements/OwnerEquity.vue'

// salary
import bowsysEmployee from './pages/bowsys-nyabo/salary/EmployeePage.vue'
import bowsysSalary from './pages/bowsys-nyabo/salary/SalaryPage.vue'
import StatisticsPage from './pages/bowsys-nyabo/salary/StatisticsPage.vue'

import FirstPage from './pages/FirstPage.vue'
// BOWSYS
const bowsysRoutes = [
  { path: '/', component: FirstPage },
  { path: '/bowsys', component: bowsysHome },
  { path: '/bowsys/login', component: bowsysLogin },
  { path: '/bowsys/balance', component: BalancePage },
  { path: '/bowsys/general-journal', component: GeneralJournal },
  { path: '/bowsys/fixed-asstes', component: FixedAssets },
  { path: '/bowsys/accounts-list', component: ChartOfAccounts },
  { path: '/bowsys/goods', component: GoodsPage },
  { path: '/bowsys/ct1', component: BalanceSheet },
  { path: '/bowsys/ct2', component: IncomeStatement },
  { path: '/bowsys/ct3', component: OwnerEquity },
  { path: '/bowsys/ct4', component: CashFlow },
  { path: '/bowsys/employee', component: bowsysEmployee },
  { path: '/bowsys/salary', component: bowsysSalary },
  { path: '/bowsys/statistics', component: StatisticsPage },
]

// MONSHIBASAKI NYABO ROUTES
const monshibasakiRoutes = [
  { path: '/monshibasaki', component: HomePage },
  { path: '/monshibasaki/salary-1', component: TsalinHuls },
  { path: '/monshibasaki/calculate', component: SalaryPage },
  { path: '/monshibasaki/time-register', component: TimeRegister },
  { path: '/monshibasaki/employee-list', component: EmployeePage },
  { path: '/monshibasaki/const-value', component: ConstValues },
  { path: '/monshibasaki/goods', component: BaraaMaterial },
  { path: '/monshibasaki/undsenHurungu', component: undsenHurungu },
  { path: '/monshibasaki/hynalt', component: Hynalt },
  { path: '/monshibasaki/sheet', component: SheetA },
  { path: '/monshibasaki/journal', component: JournalA },
  { path: '/monshibasaki/guilbal', component: GuilbalA },
  { path: '/monshibasaki/ct_1', component: Ct_1 },
  { path: '/monshibasaki/hynalt', component: Hynalt },
  { path: '/monshibasaki/ct_2', component: Ct_2 },
  { path: '/monshibasaki/ct_3', component: Ct_3 },
  { path: '/monshibasaki/ct_4', component: Ct_4 },
  { path: '/monshibasaki/tax-income', component: TaxStatement },
  { path: '/monshibasaki/login', name: 'LoginPage', component: LoginPage },
  { path: '/monshibasaki/dans_tuluvluguu', component: DansHutlult },
]

const router = createRouter({
  history: createWebHistory(),
  routes: [...monshibasakiRoutes, ...bowsysRoutes],
})

router.beforeEach(async (to, from, next) => {
  const pageTitle = to.meta?.title
  document.title = pageTitle ? `Bowsys | ${pageTitle}` : 'Bowsys'

  const routes = {
    bowsys: {
      token: Cookies.get('bowsystoken'),
      loginPath: '/bowsys/login',
      homePath: '/bowsys',
    },
    monshibasaki: {
      token: Cookies.get('monshibasakitoken'),
      loginPath: '/monshibasaki/login',
      homePath: '/monshibasaki',
    },
  }

  const matchingRoute = Object.keys(routes).find((key) => to.path.includes(`/${key}`))

  if (matchingRoute) {
    const { token, loginPath, homePath } = routes[matchingRoute]

    if (to.path === loginPath) {
      return token ? next(homePath) : next()
    } else {
      return token ? next() : next(loginPath)
    }
  }

  return next()
})

export default router
