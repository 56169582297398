<template>
  <NavBar>
    <div class="flex justify-between items-center my-8">
      <div class="flex items-center gap-3">
        <div class="relative w-52">
          <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <font-awesome-icon icon="fa-search" class="h-3.5 w-3.5 text-gray-400" />
          </div>
          <input
            type="search"
            id="default-search"
            class="font-medium block w-full p-3 ps-10 text-gray-400 border border-gray-200 rounded-sm bg-gray-100 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Хайх..."
            required
          />
        </div>
        <div class="flex justify-center items-center w-10 h-10 border-gray-200 rounded-sm bg-gray-100">
          <font-awesome-icon icon="fa-calendar-days" class="h-4 w-4 text-gray-400" />
        </div>
        <div class="relative">
          <button
            id="dropdownDefaultButton"
            data-dropdown-toggle="dropdown"
            class="font-medium rounded-sm w-44 px-5 py-3 flex justify-between items-center bg-gray-100 text-gray-400 border border-gray-200"
            type="button"
            @click="filter"
          >
            Dropdown button
            <font-awesome-icon icon="fa-chevron-down" class="h-3.5 w-3.5 text-gray-400" />
          </button>

          <!-- Dropdown menu -->
          <div v-if="activeStatus" class="z-[9998] bg-white divide-y divide-gray-100 rounded-sm shadow w-44 absolute top-12">
            <ul class="py-2 text-sm text-gray-700">
              <li>
                <a href="#" class="block px-4 py-2 hover:bg-gray-100">Dashboard</a>
              </li>
              <li>
                <a href="#" class="block px-4 py-2 hover:bg-gray-100">Settings</a>
              </li>
              <li>
                <a href="#" class="block px-4 py-2 hover:bg-gray-100">Earnings</a>
              </li>
              <li>
                <a href="#" class="block px-4 py-2 hover:bg-gray-100">Sign out</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="relative">
          <button
            id="dropdownDefaultButton"
            data-dropdown-toggle="dropdown"
            class="font-medium rounded-sm w-44 px-5 py-3 flex justify-between items-center bg-gray-100 text-gray-400 border border-gray-200"
            type="button"
            @click="filter"
          >
            Dropdown button
            <font-awesome-icon icon="fa-chevron-down" class="h-3.5 w-3.5 text-gray-400" />
          </button>

          <!-- Dropdown menu -->
          <div v-if="activeStatus" class="z-[9998] bg-white divide-y divide-gray-100 rounded-sm shadow w-44 absolute top-12">
            <ul class="py-2 text-sm text-gray-700">
              <li>
                <a href="#" class="block px-4 py-2 hover:bg-gray-100">Dashboard</a>
              </li>
              <li>
                <a href="#" class="block px-4 py-2 hover:bg-gray-100">Settings</a>
              </li>
              <li>
                <a href="#" class="block px-4 py-2 hover:bg-gray-100">Earnings</a>
              </li>
              <li>
                <a href="#" class="block px-4 py-2 hover:bg-gray-100">Sign out</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="relative">
          <button
            id="dropdownDefaultButton"
            data-dropdown-toggle="dropdown"
            class="font-medium rounded-sm w-44 px-5 py-3 flex justify-between items-center bg-gray-100 text-gray-400 border border-gray-200"
            type="button"
            @click="filter"
          >
            Dropdown button
            <font-awesome-icon icon="fa-chevron-down" class="h-3.5 w-3.5 text-gray-400" />
          </button>

          <!-- Dropdown menu -->
          <div v-if="activeStatus" class="z-[9998] bg-white divide-y divide-gray-100 rounded-sm shadow w-44 absolute top-12">
            <ul class="py-2 text-sm text-gray-700">
              <li>
                <a href="#" class="block px-4 py-2 hover:bg-gray-100">Dashboard</a>
              </li>
              <li>
                <a href="#" class="block px-4 py-2 hover:bg-gray-100">Settings</a>
              </li>
              <li>
                <a href="#" class="block px-4 py-2 hover:bg-gray-100">Earnings</a>
              </li>
              <li>
                <a href="#" class="block px-4 py-2 hover:bg-gray-100">Sign out</a>
              </li>
            </ul>
          </div>
        </div>
        <button class="flex justify-center items-center w-10 h-10 border-gray-300 rounded-sm bg-[#066BAF] shadow-lg">
          <font-awesome-icon icon="fa-xmark" class="h-[19px] w-[19px] text-white" />
        </button>
      </div>

      <div class="flex items-center gap-3">
        <button class="bg-[#049758] text-[#CDC9C5] py-3 px-4 flex justify-center items-center gap-3 rounded-sm shadow-lg font-bold" @click="addRow">
          <font-awesome-icon icon="fa-add" class="h-4 w-4 text-gary-50" />
          <p>Мөр нэмэх</p>
        </button>

        <button
          class="bg-[#049758] text-[#CDC9C5] py-3 px-4 flex justify-center items-center gap-3 rounded-sm shadow-lg font-bold"
          @click="saveEmployeeAndDetails"
        >
          <font-awesome-icon icon="fa-floppy-disk" class="h-4 w-4 text-gary-50" />
          <p>Хадгалах</p>
        </button>
      </div>
    </div>

    <!-- TABLE -->
    <div class="mt-4">
      <!-- <div id="hot"></div> -->
      <HotTable :settings="tableSettings" ref="hotRef" class="custom-handsontable" />
    </div>
  </NavBar>
</template>

<script>
  import { ref, onMounted, watch } from 'vue'
  import { useStore } from 'vuex'

  import { HotTable } from '@handsontable/vue3'
  import { registerAllModules } from 'handsontable/registry'

  import NavBar from '../../components/ui/NavBar'

  registerAllModules()
  export default {
    name: 'EmployeePage',
    components: { NavBar, HotTable },
    setup() {
      const store = useStore()
      const activeStatus = ref(false)
      const fixedData = ref([])
      const hotRef = ref(null)

      const filter = () => {
        activeStatus.value = !activeStatus.value
      }

      const commonRenderer = (instance, td, row, col, prop, value) => {
        td.innerHTML = value
        td.classList.add('htCenter', 'htMiddle')
        td.style.color = 'rgb(71 85 105)'
        td.style.background = '#CAEEC2'
      }

      const timeRenderer = (instance, td, row, col, prop, value) => {
        td.innerHTML = value
        td.classList.add('htCenter', 'htMiddle')
        td.style.color = 'rgb(71 85 105)'
        td.style.background = '#D1E5F4'
      }

      const additionalRenderer = (instance, td, row, col, prop, value) => {
        td.innerHTML = value
        td.classList.add('htCenter', 'htMiddle')
        td.style.color = 'rgb(71 85 105)'
        td.style.background = '#f8f8ba'
      }

      const tableSettings = {
        data: fixedData.value && fixedData.value.length > 0 ? fixedData.value : [{}],
        licenseKey: 'non-commercial-and-evaluation',
        colWidths: 160,
        rowHeaders: true,
        width: '100%',
        height: 'auto',
        // fixedColumnsStart: 0,
        manualColumnFreeze: true,
        rowHeights: 35,
        colHeaders: [
          'Ажилтны овог',
          'Ажилтны нэр',
          'Регистрийн дугаар',
          'Албан тушаал',
          'Даатгуулагчийн</br> төрөл',
          'Ажил мэргэжлийн </br>ангилал',
          'Үндсэн цагийн үнэлгээ </br> 100%',
          'Илүү цагийн үнэлгээ </br> 150%',
          'Өглөө, орой </br>цагийн үнэлгээ 125%',
          'Шөнийн цагийн үнэлгээ </br> 150%',
          'цагийн бонус үнэлгээ',
          'МУЧ, ажлын</br> туршлагын нэмэгдэл',
          'Удирдан ЗБ ур</br> чадварын нэмэгдэл',
          'Ажилсан жилийн </br> нэмэгдэл',
          'БО-н 1%',
          'Хүүхдийн тоо',
          'Бусад',
        ],
        columns: [
          { data: 'last_name', renderer: commonRenderer },
          { data: 'first_name', renderer: commonRenderer },
          { data: 'register_num', renderer: commonRenderer },
          { data: 'position', renderer: commonRenderer },
          { data: 'insurance_type', renderer: commonRenderer },
          { data: 'job_categories', renderer: commonRenderer },
          { data: 'undsen_tsag', renderer: timeRenderer },
          { data: 'iluu_tsag', renderer: timeRenderer },
          { data: 'ogloo_oroi_tsag', renderer: timeRenderer },
          { data: 'shoniin_tsag', renderer: timeRenderer },
          { data: 'tsagiin_bonus', renderer: timeRenderer },
          { data: 'ajliin_turshlaga', renderer: additionalRenderer },
          { data: 'ur_chadvar', renderer: additionalRenderer },
          { data: 'ajillasan_jil', renderer: additionalRenderer },
          { data: 'borluulalt_orlogo_huvi' },
          { data: 'huuhed_too', renderer: additionalRenderer },
          { data: 'busad' },
        ],
        afterChange: async (changes, source) => {
          if (source === 'edit' || source === 'paste') {
            for (const [row, col, oldValue, newValue] of changes) {
              if (oldValue === newValue) {
                continue
              }

              const hotInstance = hotRef.value.hotInstance
              const rowData = hotInstance.getDataAtRow(row)

              if (col === 'undsen_tsag' && newValue) {
                console.log(rowData, newValue, col)

                const numValue = parseFloat(newValue)
                if (isNaN(numValue)) {
                  continue // Skip if newValue is not a number
                }

                hotInstance.setDataAtCell(row, 7, (numValue * 1.5).toFixed(2))
                hotInstance.setDataAtCell(row, 8, (numValue * 1.25).toFixed(2))
                hotInstance.setDataAtCell(row, 9, (numValue * 1.5).toFixed(2))
                hotInstance.setDataAtCell(row, 10, numValue.toFixed(2))
              }
            }
          }
        },
      }

      onMounted(async () => {
        const res = await store.dispatch('fetchAllEmployeesAndDetails')
        fixedData.value = res.data
      })

      watch(fixedData, (newData) => {
        hotRef.value.hotInstance.loadData(newData.length > 0 ? newData : [{}])
      })

      const addRow = () => {
        // hotInstance.value.alter(
        //   "insert_row_below",
        //   hotInstance.value.countRows()
        // );
        // const newRowIndex = hotInstance.value.countRows() - 1;
        // rowIndex.value = newRowIndex;

        const hotInstance = hotRef.value.hotInstance
        const newRow = hotInstance.countRows()

        hotInstance.alter('insert_row_below', newRow)
      }

      const saveEmployeeAndDetails = async () => {
        try {
          const allSalaryData = hotRef.value.hotInstance.getData()
          const res = await store.dispatch('insertNewEmployee', allSalaryData)
          if (res.data.message == 'successfully') {
            window.location.reload()
          }
        } catch (error) {
          console.error(error)
          throw error
        }
      }

      return {
        filter,
        addRow,
        activeStatus,
        saveEmployeeAndDetails,
        fixedData,
        hotRef,
        tableSettings,
      }
    },
  }
</script>

<!-- <style>
.custom-handsontable .ht_clone_top th {
  border-left: none !important;
  border-right: none !important;
  background-color: rgb(229 231 235);
  color: rgb(75 85 99) !important;
}

.custom-handsontable .ht_master .wtHolder .wtHider .wtSpreader table td,
.custom-handsontable .ht_master .wtHolder .wtHider .wtSpreader table th {
  border-left: none !important; /* Removes left vertical borders */
  border-right: none !important; /* Removes right vertical borders */
}

.custom-handsontable .ht_master .wtHolder .wtHider .wtSpreader table td {
  border-bottom: 1px solid #ddd !important; /* Keeps the bottom borders if needed */
}
</style> -->
