<template>
  <NavBar>
    <br />
    <div class="flex flex-col items-center text-red-500" v-if="!data">
      <h1>Мэдээлэл олдсонгүй!!!</h1>
    </div>

    <!-- <div class="flex flex-col items-center gap-4" v-if="data">
        <h1>9. Ct_2</h1>
        <hot-table :settings="hotSettings3" :data="data.a9"> </hot-table>
  
        <div class="flex gap-3">
          <h1>ХМ-02(3A)</h1>
          <p>Нийт зардлын хувиарлалт</p>
        </div>
        <hot-table :settings="hotSettings" :data="data.niit_zardal"> </hot-table>
  
        <p>Татвар ноогдох орлогоос хасагдах зарлагын мэдээ</p>
  
        <hot-table :settings="hotSettings2" :data="data.zardal_list"> </hot-table>
  
        <div class="flex justify-between w-96 my-16">
          <div>
            <p class="mb-4">Ерөний захирал</p>
            <p>Ерөний нягтлан бодогч</p>
          </div>
          <div>
            <p class="mb-4">/Б.Чулуунсүх/</p>
            <p>/С.Отгонсайхан/</p>
          </div>
        </div>
      </div> -->

    <div class="flex flex-col items-center" v-if="data">
      <div class="flex gap-10 text-sm my-3">
        <div class="flex gap-3 items-center">
          <p>Жил:</p>
          <select name="uliral" id="uliral" class="p-1 rounded-sm w-24">
            <option value="1">2023</option>
            <option value="2">2024</option>
            <option value="3">2026</option>
            <option value="4">2027</option>
          </select>
        </div>
        <div class="flex gap-3 items-center">
          <p>Улирал:</p>
          <select name="uliral" id="uliral" class="p-1 rounded-sm w-24">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </select>
        </div>
        <div class="flex gap-3 items-center">
          <p>Баталгаажуулах</p>
          <input type="checkbox" />
        </div>
      </div>
      <div class="bg-white w-[900px] flex flex-col items-center py-4">
        <h1 class="font-bold text-lg">ОРЛОГЫН ДЭЛГЭРЭНГҮЙ ТАЙЛАН</h1>
        <div class="flex justify-between w-[780px] my-3">
          <div>
            <p class="font-bold border-b-2 border-gray-600 p-1">Саруул ХХК</p>
            <p class="p-1">(Аж ахуйн нэгжийн нэр )</p>
          </div>
          <div class="flex items-center gap-3">
            <p>Тайлант үе:</p>
            <p>2023.01.01 - 2023.12.31 /төгрөгөөр/</p>
          </div>
        </div>
        <hot-table :settings="hotSettings3" :data="data"> </hot-table>
        <div class="flex justify-between w-96 my-16">
          <div>
            <p class="mb-4">Ерөнхий захирал:</p>
            <p>Ерөнхий нягтлан бодогч:</p>
          </div>
          <div>
            <p class="mb-4">/Б.Чулуунсүх/</p>
            <p>/С.Отгонсайхан/</p>
          </div>
        </div>
      </div>
    </div>
  </NavBar>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import Handsontable from "handsontable";
import "handsontable/dist/handsontable.full.css";
import NavBar from "@/components/ui/NavBar.vue";

Handsontable.renderers.registerRenderer(
  "customStylesRenderer",
  (hotInstance, TD, ...rest) => {
    Handsontable.renderers.TextRenderer(hotInstance, TD, ...rest);
    TD.style.fontWeight = "bold";
  }
);

registerAllModules();

export default {
  name: "TaxStatement",
  components: {
    HotTable,
    NavBar,
  },
  setup() {
    const store = useStore();
    const data = ref();

    onMounted(async () => {
      try {
        data.value = await store.dispatch(
          "fetchIncomeStatementByYearAndSeason",
          {
            year: 2023,
            season: 1,
          }
        );
      } catch (error) {
        return error;
      }
    });

    const hotSettings2 = {
      licenseKey: "non-commercial-and-evaluation",
      rowHeaders: true,
      colHeaders: ["", "Дүн"],
      columns: [
        {
          data: "dansnii_ner",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
        {
          data: "etsiin_uldegdel_debit",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
      ],
    };

    const hotSettings = {
      licenseKey: "non-commercial-and-evaluation",
      rowHeaders: true,
      colWidths: [80, 300, 200, 200],

      colHeaders: ["", "Дүн"],
      columns: [
        {
          data: "dansnii_ner",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
        {
          data: "etsiin_uldegdel_debit",
          type: "numeric",
          numericFormat: { pattern: "0,0" },
        },
      ],
    };

    const hotSettings3 = {
      licenseKey: "non-commercial-and-evaluation",
      colWidths: [80, 400, 300],
      colHeaders: [
        "Мөрийн </br> дугаар",
        "Үзүүлэлт",
        "20XX оны 1-р улирал",
        "",
      ],
      columns: [
        { data: "row_no" },
        { data: "statement_name" },
        {
          data: "amount",
          renderer: function (instance, td, row, col, prop, value) {
            td.style.textAlign = "right";
            if (typeof value === "number") {
              if (value < 0) {
                td.innerHTML = `(${Math.abs(value).toLocaleString()})`;
              } else if (value === 0) {
                td.innerHTML = "";
              } else {
                td.innerHTML = value.toLocaleString();
              }
            } else {
              td.innerHTML = value;
            }
            return td;
          },
        },
      ],
    };

    return {
      data,
      hotSettings,
      hotSettings2,
      hotSettings3,
    };
  },
};
</script>
