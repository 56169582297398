<template>
  <BowsysNavbar>
    <SpinnerCom v-if="isLoading" class="text-red-500 flex justify-center items-center min-h-svh" />

    <div class="flex flex-col justify-center w-11/12 mx-auto bg-white p-6" v-else>
      <h1 class="font-bold text-lg text-center my-4">ӨМЧИЙН ӨӨРЧЛӨЛТИЙН ТАЙЛАН</h1>
      <div class="flex justify-between">
        <div>
          <p class="font-bold border-b-2 border-gray-600 p-1">BOW ХХК</p>
          <p class="p-1">(Аж ахуйн нэгжийн нэр )</p>
        </div>
        <div class="flex items-center gap-3">
          <p>Тайлант үе:</p>
          <p>2023.01.01 - 2023.12.31 /төгрөгөөр/</p>
        </div>
      </div>
      <div>
        <hot-table v-if="list?.length > 0" :data="list" :settings="hotSettings" ref="hotRef" class="mt-6"></hot-table>
        <p v-else class="text-center font-bold my-4 text-red-500">Хоосон байна.</p>
      </div>
      <div class="flex justify-evenly my-16">
        <div>
          <p class="mb-4">Ерөний захирал</p>
          <p>Ерөний нягтлан бодогч</p>
        </div>
        <div>
          <p class="mb-4">/З.Энхболд/</p>
          <p>/З.Дашдэмбэрэл/</p>
        </div>
      </div>
    </div>
  </BowsysNavbar>
</template>

<script>
  import { ref, onMounted, watch, computed } from 'vue'
  import { useStore } from 'vuex'
  import { HotTable } from '@handsontable/vue3'
  import { registerAllModules } from 'handsontable/registry'
  import 'handsontable/dist/handsontable.full.min.css'
  import 'handsontable/dist/handsontable.full.css'

  import BowsysNavbar from '@/components/bowsys/BowsysNavbar.vue'
  import SpinnerCom from '@/components/ui/SpinnerCom.vue'

  registerAllModules()

  export default {
    name: 'OwnerEquity',
    components: { BowsysNavbar, SpinnerCom, HotTable },

    setup() {
      const store = useStore()

      const isLoading = ref(false)
      const hotRef = ref(null)

      /*
       **************************************** SHOW JOURNAL LIST ****************************************
       */

      const commonRenderer = (instance, td, row, col, prop, value) => {
        td.classList.add('htMiddle')

        td.innerHTML = value

        if (col !== 0 && col !== 1) {
          const numericValue = parseFloat(value)

          if (value === null || value === '' || isNaN(numericValue) || numericValue === 0) {
            td.innerHTML = '-'
          } else if (numericValue > 0) {
            const isWholeNumber = numericValue % 1 === 0

            const formattedValue = isWholeNumber
              ? numericValue.toLocaleString(undefined)
              : numericValue.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })

            td.innerHTML = formattedValue
          }
          td.classList.add('htMiddle', 'htRight')
        }
      }

      const hotSettings = {
        licenseKey: 'non-commercial-and-evaluation',
        colHeaders: [
          'Мөрийн </br> дугаар',
          'Үзүүлэлт',
          'Өмч',
          'Халаасны  </br> хувьцаа',
          'Нэмж төлөгдсөн </br> капитал',
          'Хөрөнгийн дахин </br> үнэлгээний </br> нөөц',
          'Гадаад валютын </br> хөрвүүлэлтийн  </br> нөөц',
          'Эздийн өмчийн </br>бусад хэсэг',
          'Хуримтлагдсан  </br> ашиг /алдагдал/',
          'Нийт дүн',
        ],
        // columns: [
        //   { data: 'row_no', title: 'Мөрийн </br> дугаар', renderer: commonRenderer },
        //   { data: 'statement_name', title: 'Үзүүлэлт', renderer: commonRenderer },
        //   { title: '20XX оны 1 сарын 1', data: 'initial_balance', renderer: commonRenderer },
        //   { title: '20XX оны 1-р улирал', data: 'final_balance', renderer: commonRenderer },
        // ],
        columns: [
          { data: 'row_no', renderer: commonRenderer },
          { data: 'statement_name', renderer: commonRenderer },
          { data: 'property', renderer: commonRenderer },
          { data: '', renderer: commonRenderer },
          { data: '', renderer: commonRenderer },
          { data: '', renderer: commonRenderer },
          { data: '', renderer: commonRenderer },
          { data: '', renderer: commonRenderer },
          { data: 'accumulatedProfit', renderer: commonRenderer },
          { data: 'totalRemain', renderer: commonRenderer },
        ],
        colWidths: (index) => (index == 0 ? 60 : index == 1 ? 220 : 120),
        stretchH: 'all',
        height: 'auto',
      }

      const list = computed(() => store.getters['bowsys/getOwnerEquity'])

      onMounted(async () => {
        try {
          isLoading.value = true
          const res = await store.dispatch('bowsys/fetchOwnerEquity')

          console.log('first', list.value)
          if (res.message === 'successfully') {
            isLoading.value = false
          }
        } catch (error) {
          console.error(error)
          throw error
        }
      })

      watch(list, (newVal, oldVal) => {
        if (newVal !== oldVal && hotRef.value) {
          hotRef.value.hotInstance.loadData(newVal)
        }
      })

      return { hotSettings, list, isLoading }
    },
  }
</script>
