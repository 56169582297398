export default {
  state() {
    return {
      route: JSON.parse(localStorage.getItem("monshibasakiRoute")) || [],
    };
  },

  getters: {
    getRoute(state) {
      return state.route;
    },
  },

  mutations: {
    setRoute(state, payload) {
      state.route = payload;
      localStorage.setItem("monshibasakiRoute", JSON.stringify(payload));
    },
  },

  actions: {
    async fetchRoute(context, payload) {
      try {
        context.commit("setRoute", payload);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
