import axios from "axios";

let baseURL = "";

if (process.env.NODE_ENV === "production") {
  baseURL = "https://boyo.mn";
} else {
  baseURL = "http://localhost";
}

const instance = axios.create({
  baseURL: `${baseURL}/bowsys/backend`,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
  withCredentials: true,
});

const clientInstance = axios.create({
  baseURL: `${baseURL}/backend`,
});

export { instance, clientInstance };
