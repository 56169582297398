<template>
  <div
    class="flex justify-center items-center h-screen bg-primary bg-slate-200"
  >
    <div
      class="bg-[#FCFCF7] w-1/2 h-72 flex flex-col gap-6 justify-center items-center rounded-lg text-primary font-bold"
    >
      <p class="text-2xl text-center">
        WELCOME TO <br />
        <span class="text-lg"> MONSHIBASAKI NYBO SYSTEM</span>
      </p>

      <div id="buttonDiv"></div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted } from "vue";
import Cookies from "js-cookie";

export default {
  name: "LoginPage",
  setup() {
    const store = useStore();

    const handleCredentialResponse = async (response) => {
      try {
        const res = await store.dispatch("checkidToken", {
          idToken: response.credential,
        });
        if (res.data.statusCode == 200) {
          setTokenAndRedirect(res);
        } else {
          alert(res.data.message);
        }
      } catch (e) {
        console.error(e);
      }
    };

    const initializeGoogleSignIn = () => {
      window.google.accounts.id.initialize({
        client_id:
          "490593174633-43qpekcbffkh721e8evi1emq4bh8scuf.apps.googleusercontent.com",
        callback: handleCredentialResponse,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        { theme: "outline", size: "large" }
      );
      window.google.accounts.id.prompt();
    };

    onMounted(() => {
      initializeGoogleSignIn();
    });

    const setTokenAndRedirect = async (res) => {
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 3 * 60 * 60 * 1000);
      Cookies.set("monshibasakitoken", res.data.token, {
        expires: expirationDate,
      });
      if (res.status) {
        await store.dispatch("fetchRoute", {
          name: "Home",
          icon: "fa-home",
          path: "/monshibasaki",
        });

        window.location.href = "/monshibasaki";
      }
    };

    return {
      handleCredentialResponse,
      initializeGoogleSignIn,
    };
  },
};
</script>
