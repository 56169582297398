<template>
  <BowsysNavbar>
    <div>Fixed Assets</div>
  </BowsysNavbar>
</template>

<script>
import BowsysNavbar from "@/components/bowsys/BowsysNavbar.vue";

export default {
  name: "FixedAssets",
  components: { BowsysNavbar },
  setup() {
    return {};
  },
};
</script>
