<template>
  <NavBar>
    <p class="flex flex-col items-center">HomePage is empty</p>
  </NavBar>
  <!-- <h2><a href="./salary/">Цалингийн хүснэгт</a></h2>
  <h2><a href="./goods/">Бараа материал</a></h2>
  <h2><a href="./undsenHurungu/">Үндсэн хөрөнгө</a></h2>
  <h2><a href="./sheet/">Sheet1</a></h2>
  <h2><a href="./journal/">Ерөнхий журнал</a></h2>
  <h2><a href="./guilbal/">Гүйлгээ баланс</a></h2>
  <h2><a href="./hynalt/">Хяналт</a></h2>
  <h2><a href="./ct_1/">Ct_1</a></h2>
  <h2><a href="./ct_2">Ct_2</a></h2>
  <h2><a href="./ct_3/">Ct_3</a></h2>
  <h2><a href="./ct_4">Ct_4</a></h2>
  <h2><a href="./dans_tuluvluguu/">Дансны төлөвлөгөө</a></h2> -->
</template>

<script>
import NavBar from "@/components/ui/NavBar.vue";

export default {
  components: { NavBar },
};
</script>
