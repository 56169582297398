import { createStore } from "vuex";
import user_side from "./public";
import nav from "./navigation";
import salary from "./salary";
import bowsys from "./bowsys";

export default createStore({
  namespaced: true,
  modules: {
    user_side,
    nav,
    salary,
    bowsys,
  },
});
