<template>
  <BowsysNavbar>
    <SpinnerCom v-if="isLoading" class="text-red-500 flex justify-center items-center min-h-svh" />

    <div v-else>
      <button
        class="bg-[#049758] hover:bg-green-700 text-[#CDC9C5] py-3 px-4 flex justify-center items-center gap-3 rounded-sm shadow-lg font-bold"
        @click="isModalOpen = true"
      >
        <font-awesome-icon icon="fa-add" class="h-4 w-4 text-gary-50" />
        <p>Ажил гүйлгээ нэмэх</p>
      </button>

      <ModalCom
        :isOpen="isModalOpen"
        modalWidth="m-8"
        title="Шинэ ажил гүйлгээ нэмэх"
        @close="isModalOpen = false"
        @save="saveNewJournal"
        :message="message"
      >
        <div>
          <hot-table :settings="tableSettings" ref="tableRef" class="custom-journal"></hot-table>
        </div>
      </ModalCom>

      <h1 class="text-base my-4 font-bold">
        Нийт
        <span>({{ list?.data?.length }})</span>
      </h1>

      <div>
        <hot-table v-if="list?.data?.length > 0" :data="list.data" :settings="hotSettings" ref="hotRef" class="custom-journal mt-6"></hot-table>
        <p v-else class="text-center font-bold my-4 text-red-500">Хоосон байна.</p>
      </div>
    </div>
  </BowsysNavbar>
</template>

<script>
  import { ref, reactive, onMounted, watch, computed } from 'vue'
  import { useStore } from 'vuex'
  import { HotTable } from '@handsontable/vue3'
  import { registerAllModules } from 'handsontable/registry'
  import 'handsontable/dist/handsontable.full.min.css'
  import 'handsontable/dist/handsontable.full.css'

  import BowsysNavbar from '@/components/bowsys/BowsysNavbar.vue'
  import SpinnerCom from '@/components/ui/SpinnerCom.vue'
  import ModalCom from '@/components/bowsys/ModalCom.vue'

  registerAllModules()

  export default {
    name: 'GeneralJournal',
    components: { BowsysNavbar, HotTable, SpinnerCom, ModalCom },

    setup() {
      const store = useStore()

      const list = ref([])
      const isLoading = ref(false)
      const hotRef = ref(null)
      const tableRef = ref(null)
      const isModalOpen = ref(false)

      const message = reactive({
        text: '',
        isVisible: false,
      })

      /*
       **************************************** ADD NEW JOURNAL ****************************************
       */

      const isValue = reactive({
        code: '',
        date: '',
        description: '',
        amount: '',
        debit: '',
        credit: '',
        partner: '',
      })

      const initialData = ref([
        { date: '', code: '', description: '', amount: '', debit: null, credit: null, partner: '' },
        { date: '', code: '', description: '', amount: '', debit: null, credit: null, partner: '' },
      ])

      const tableRenderer = (instance, td, row, col, prop, value) => {
        td.classList.add('htCenter', 'htMiddle')
        td.style.color = '#828282'

        td.innerHTML = value

        const displayValue = value !== null && value !== undefined ? value : ''

        if (col == 0) {
          const input = document.createElement('input')
          input.type = 'date'
          input.value = displayValue

          input.classList.add('date-input')
          input.style.width = '1/2'
          input.style.padding = '5px'
          input.style.border = 'none'
          input.style.backgroundColor = 'transparent'

          input.addEventListener('change', (event) => {
            const newDate = event.target.value
            instance.setDataAtCell(row, col, newDate)
          })

          td.innerHTML = ''
          td.appendChild(input)
        } else if ((col >= 1 && col <= 3) || col == 6) {
          const inputWidth = [2, 6].includes(col) ? 'w-11/12' : 'w-4/5'
          const formattedValue =
            col === 3 && !isNaN(parseFloat(displayValue))
              ? parseFloat(displayValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              : displayValue

          td.innerHTML = `<input type="text" value="${formattedValue}" class="border border-[#ccc] p-0.5 m-0.5 text-center rounded-sm ${inputWidth}"/>`

          const inputElement = td.querySelector('input')

          inputElement.addEventListener('change', () => {
            let newValue = inputElement.value

            if (col === 3) {
              newValue = parseFloat(newValue.replace(/,/g, '')).toFixed(2)
            }

            instance.setDataAtCell(row, col, newValue)
          })
        } else if (col == 4 || col == 5) {
          // const select = document.createElement('select')
          // select.classList.add('border', 'border-[#ccc]', 'p-1', 'text-center', 'rounded-sm', 'w-32')
          // const placeholderOption = document.createElement('option')
          // placeholderOption.value = ''
          // placeholderOption.text = 'Select account'
          // placeholderOption.disabled = true
          // placeholderOption.selected = true
          // select.appendChild(placeholderOption)
          // accountNumberList?.value.forEach((optionValue) => {
          //   const option = document.createElement('option')
          //   option.value = optionValue.account_number
          //   option.text = optionValue.account_number
          //   if (optionValue.account_number === displayValue) {
          //     option.selected = true
          //   }
          //   select.appendChild(option)
          // })
          // select.addEventListener('change', (event) => {
          //   const selectedValue = event.target.value
          //   instance.setDataAtCell(row, col, selectedValue)
          // })
          // td.innerHTML = ''
          // td.appendChild(select)
          // ******************************** TEST 2 ********************************
          // const select = document.createElement('select')
          // select.classList.add('border', 'border-[#ccc]', 'p-0.5', 'm-0.5', 'text-center', 'rounded-sm', 'w-32')
          // accountNumberList?.value.forEach((optionValue) => {
          //   const option = document.createElement('option')
          //   option.value = optionValue.account_number
          //   option.text = optionValue.account_number
          //   if (optionValue.account_number === displayValue) {
          //     option.selected = true
          //   }
          //   select.appendChild(option)
          // })
          // select.addEventListener('change', (event) => {
          //   const selectedValue = event.target.value
          //   instance.setDataAtCell(row, col, selectedValue)
          // })
          // td.innerHTML = ''
          // td.appendChild(select)
          // ******************************** TEST 3 ********************************
          const inputElement = document.createElement('input')
          inputElement.type = 'text'
          inputElement.value = displayValue
          inputElement.classList.add('border', 'border-[#ccc]', 'p-0.5', 'm-0.5', 'text-center', 'rounded-sm', 'w-32')
          const dropdown = document.createElement('ul')
          dropdown.classList.add('dropdown-list')
          dropdown.style.width = '200px'
          dropdown.style.zIndex = '9999'
          dropdown.style.border = '1px solid #ccc'
          dropdown.style.padding = '0'
          dropdown.style.margin = '0'
          dropdown.style.listStyleType = 'none'
          dropdown.style.position = 'absolute'
          dropdown.style.backgroundColor = 'white'
          dropdown.style.display = 'none'

          td.innerHTML = ''
          td.appendChild(inputElement)
          td.appendChild(dropdown)

          let selectedIndex = -1

          inputElement.addEventListener('input', () => {
            const filterValue = inputElement.value.toLowerCase()
            dropdown.innerHTML = ''
            dropdown.style.display = 'none'
            if (filterValue) {
              const filteredData = accountNumberList?.value.filter((item) => item.account_number.includes(filterValue))
              if (filteredData.length > 0) {
                dropdown.style.display = 'block'
                filteredData.forEach((item, index) => {
                  const listItem = document.createElement('li')
                  listItem.textContent = item.account_number + ' ' + item.account_name
                  listItem.style.padding = '5px'
                  listItem.style.cursor = 'pointer'
                  listItem.addEventListener('click', () => {
                    inputElement.value = item.account_number
                    instance.setDataAtCell(row, col, item.account_number)
                    dropdown.style.display = 'none'
                  })
                  dropdown.appendChild(listItem)
                  if (index === selectedIndex) {
                    listItem.style.backgroundColor = '#e0e0e0'
                  }
                })
              }
            }
          })
          inputElement.addEventListener('keydown', (e) => {
            const items = dropdown.querySelectorAll('li')
            if (e.key === 'ArrowDown') {
              selectedIndex = (selectedIndex + 1) % items.length
              e.preventDefault()
            } else if (e.key === 'ArrowUp') {
              selectedIndex = (selectedIndex - 1 + items.length) % items.length
              e.preventDefault()
            } else if (e.key === 'Enter') {
              if (items[selectedIndex]) {
                inputElement.value = items[selectedIndex].textContent
                instance.setDataAtCell(row, col, inputElement.value)
                dropdown.style.display = 'none'
              }
              e.preventDefault()
            }
            items.forEach((item, index) => {
              item.style.backgroundColor = index === selectedIndex ? '#e0e0e0' : 'transparent'
            })
          })
          // inputElement.addEventListener('blur', () => {
          //   setTimeout(() => {
          //     dropdown.style.display = 'none'
          //   }, 100)
          // })
        } else {
          td.innerHTML = displayValue
        }

        if (col === 7) {
          td.innerHTML = `<div class="flex items-center justify-center gap-5">
            <button class="add"><img src="https://img.icons8.com/?size=96&id=102544&format=png" alt="" class="h-7 w-7 object-fit p-1 rounded-lg"/></button>
            <button class="delete"><img src="https://img.icons8.com/?size=96&id=63688&format=png" alt="" class="h-7 w-7 object-fit p-1 rounded-lg"/></button>
          </div>`
          const deleteButton = td.querySelector('.delete')
          const addButton = td.querySelector('.add')

          if (deleteButton) {
            deleteButton.addEventListener('click', async () => {
              if (tableSettings.value.data.length > 2) {
                tableSettings.value.data.splice(row, 1)

                instance.render()
              } else {
                message.text = '2 дээш мөр байгаа тохиолдолд устгаж болно.'
                message.isVisible = true

                setTimeout(() => {
                  message.isVisible = false
                }, 3000)
              }
            })
          }

          if (addButton) {
            addButton.addEventListener('click', () => {
              const newRow = { date: '', code: '', description: '', amount: '', debit: null, credit: null, partner: '' }
              tableSettings.value.data.splice(row + 1, 0, newRow)
              instance.render()
              console.log('add')
            })
          }
        }
      }

      const tableSettings = ref({
        data: initialData.value,
        columns: [
          { title: 'Огноо', data: 'date', readOnly: true, renderer: tableRenderer },
          { title: 'Код', data: 'code', readOnly: true, renderer: tableRenderer },
          { title: 'Гүйлгээний утга', data: 'description', readOnly: true, renderer: tableRenderer },
          { title: 'Дүн', data: 'amount', readOnly: true, type: 'numeric', numericFormat: { pattern: '0,0.00' }, renderer: tableRenderer },
          { title: 'Дебет харилцах', data: 'debit', readOnly: true, renderer: tableRenderer },
          { title: 'Кредит харилцах', data: 'credit', readOnly: true, renderer: tableRenderer },
          { title: 'Харилцагч', data: 'partner', readOnly: true, renderer: tableRenderer },
          { title: 'Нэмэх / Устгах', data: 'button', readOnly: true, renderer: tableRenderer },
        ],
        colHeaders: true,
        rowHeights: 40,
        stretchH: 'all',
        width: '100%',
        maxHeights: 500,
        licenseKey: 'non-commercial-and-evaluation',
        colWidths: (index) => (index == 1 ? 55 : [2, 6].includes(index) ? 200 : 120),
      })

      /*
       **************************************** SHOW JOURNAL LIST ****************************************
       */

      const commonRenderer = (instance, td, row, col, prop, value) => {
        td.classList.add('htCenter', 'htMiddle')
        td.style.color = '#828282'

        td.innerHTML = value

        if (col == 4) {
          const formattedValue = parseFloat(value).toLocaleString()
          td.innerHTML = formattedValue
        }
      }

      const hotSettings = {
        licenseKey: 'non-commercial-and-evaluation',
        stretchH: 'all',
        height: 'auto',
        rowHeights: 35,
        hiddenColumns: {
          columns: [0],
          indicators: true,
        },
        colHeaders: ['Д/д', 'Огноо', 'Код', 'Гүйлгээний утга', 'Дүн', 'Дебет харилцах', 'Кредит харилцах', 'Харилцагч байгууллага'],
        columns: [
          { data: 'id', readOnly: true, renderer: commonRenderer },
          { data: 'formatted_date', readOnly: true, renderer: commonRenderer },
          { data: 'journal_code', readOnly: true, renderer: commonRenderer },
          { data: 'transaction_description', readOnly: true, renderer: commonRenderer },
          { data: 'amount', readOnly: true, renderer: commonRenderer },
          { data: 'debit_account_number', readOnly: true, renderer: commonRenderer },
          { data: 'credit_account_number', readOnly: true, renderer: commonRenderer },
          { data: 'partner', readOnly: true, renderer: commonRenderer },
        ],
        colWidths: (index) => ([1, 2].includes(index) ? 55 : index == 3 ? 150 : 100),
      }

      const accountNumberList = computed(() => store.getters['bowsys/getAccountNumberList'])

      onMounted(async () => {
        try {
          isLoading.value = true
          list.value = await store.dispatch('bowsys/fetchJournalList')

          await store.dispatch('bowsys/fetchAccountList')

          if (list.value && list.value.message === 'successfully') {
            isLoading.value = false
          }
        } catch (error) {
          console.error(error)
          throw error
        }
      })

      watch(list, (newVal, oldVal) => {
        if (newVal !== oldVal && hotRef.value) {
          hotRef.value.hotInstance.loadData(newVal)
        }
      })

      const saveNewJournal = async () => {
        const rowData = tableRef.value.hotInstance.getData()

        try {
          const response = await store.dispatch('bowsys/addNewJournals', rowData)

          message.isVisible = true
          message.text = response.message

          if (response.message === 'Амжилттай.') {
            list.value = await store.dispatch('bowsys/fetchJournalList')
          }

          setTimeout(() => {
            message.isVisible = false
          }, 3000)
        } catch (error) {
          console.error('Error saving journal:', error)
          message.isVisible = true
          message.text = 'Алдаа гарлаа. Дахин оролдоно уу.'

          setTimeout(() => {
            message.isVisible = false
          }, 3000)
        }
      }

      return { message, hotSettings, isLoading, list, hotRef, isModalOpen, isValue, saveNewJournal, tableSettings, tableRef, accountNumberList }
    },
  }
</script>

<style>
  .dropdown-list li {
    padding: 5px;
    cursor: pointer;
  }

  .dropdown-list li:hover,
  .dropdown-list li.selected {
    background-color: #e0e0e0;
  }

  .dropdown-list {
    width: 200px; /* Adjust width here */
    z-index: 9999; /* Ensure it appears on top of other elements */
    position: absolute;
    border: 1px solid #ccc;
    background-color: white;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .custom-journal .ht_clone_top th {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
    background-color: #fcfcfc !important;
    color: #828282 !important;
  }

  .custom-journal .ht_master .wtHolder .wtHider .wtSpreader table td,
  .custom-journal .ht_master .wtHolder .wtHider .wtSpreader table th {
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-top: none !important;
  }

  .custom-journal .ht_master .wtHolder .wtHider .wtSpreader .htBorders div .wtBorder {
    background-color: inherit !important;
  }

  .custom-journal .ht_master .wtHolder .wtHider .wtSpreader table td {
    border-top: 1px solid #f3f4f5 !important;
  }
</style>
