import { clientInstance } from "../lib/instance";

export default {
  state() {
    return {
      // route: JSON.parse(localStorage.getItem("route")) || [],
    };
  },

  getters: {
    // getRoute(state) {
    //   return state.route;
    // },
  },

  mutations: {
    // setRoute(state, payload) {
    //   state.route = payload;
    //   localStorage.setItem("timeRegister", JSON.stringify(payload));
    // },
  },

  actions: {
    /* 
    shalgaj baina  ****************************************************************
    */

    // MONSHIBASAKI - EMPLOYEE PAGE - GET EMPLOYEEE AND OTHER DETAILS
    async fetchAllEmployeesAndDetails() {
      try {
        return await clientInstance.get("/api/getAllEmployeesAndDetails");
      } catch (error) {
        console.error("Error:", error);
        return error;
      }
    },

    // MONSHIBASAKI - EMPLOYEE PAGE - ADD EMPLOYEEE AND OTHER DETAILS
    async insertNewEmployee(_, payload) {
      try {
        return await clientInstance.post("/api/insertNewEmployee", payload);
      } catch (error) {
        console.error("Error:", error);
        return error;
      }
    },

    // MONSHIBASAKI - TIME REGISTER PAGE - NERS TATAH
    async fetchEmployeesWithDetails() {
      try {
        const res = await clientInstance.get("/api/getEmployeesWithDetails");
        return res.data;
      } catch (error) {
        console.error("Error:", error);
        return error;
      }
    },

    async fetchWorkedHoursById(_, payload) {
      try {
        const res = await clientInstance.post(
          "/api/getWorkedHoursById",
          payload
        );
        return res.data;
      } catch (error) {
        console.error("Error:", error);
        return error;
      }
    },

    // MONSHIBASAKI - TIME REGISTER PAGE - TSALIN BODOH tsag burtgeh
    async insertEmployeesTime(_, payload) {
      try {
        return await clientInstance.post("/api/addWorkedTime", payload);
      } catch (error) {
        console.error("Error:", error);
        return error;
      }
    },
  },
};
