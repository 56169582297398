<template>
  <BowsysNavbar>
    <SpinnerCom v-if="isLoading" class="text-red-500 flex justify-center items-center min-h-svh" />

    <div v-else>
      <div class="flex items-center justify-between gap-4">
        <div class="flex flex-col justify-center gap-1 text-gray-500">
          <label for="month" class="font-bold">Сар сонгох</label>

          <input
            type="month"
            id="start"
            name="start"
            min="2024-07"
            :value="date"
            @input="updateDate($event.target.value)"
            required
            pattern="[0-9]{4}-[0-9]{2}"
            class="border py-2 px-4 gap-3 rounded-sm"
          />
        </div>

        <button
          class="bg-[#049758] hover:bg-green-700 text-[#CDC9C5] py-3 px-4 flex justify-center items-center gap-3 rounded-sm shadow-lg font-bold"
          @click="saveSalaryAllEmployees"
        >
          <font-awesome-icon icon="fa-save" class="h-4 w-4 text-gary-50" />
          <p>Хадгалах</p>
        </button>
      </div>

      <h1 class="text-base my-4 font-bold">
        Нийт
        <span>({{ data?.list?.length }})</span>
      </h1>

      <h1 v-if="message.isVisible" class="text-center text-green-500 font-bold">{{ message.text }}</h1>

      <div>
        <hot-table v-if="data?.list?.length > 0" :data="data.list" :settings="hotSettings" ref="hotRef" class="mt-6 custom-salary"></hot-table>
        <p v-else class="text-center font-bold my-4 text-red-500">Хоосон байна.</p>
      </div>
    </div>
  </BowsysNavbar>
</template>

<script>
  import { ref, reactive, onMounted, watch, computed } from 'vue'
  import { useStore } from 'vuex'
  import { HotTable } from '@handsontable/vue3'
  import { registerAllModules } from 'handsontable/registry'
  import 'handsontable/dist/handsontable.full.min.css'
  import 'handsontable/dist/handsontable.full.css'

  import BowsysNavbar from '@/components/bowsys/BowsysNavbar.vue'
  import SpinnerCom from '@/components/ui/SpinnerCom.vue'

  registerAllModules()

  export default {
    name: 'SalaryPage',
    components: { BowsysNavbar, HotTable, SpinnerCom },
    setup() {
      const store = useStore()

      const isLoading = ref(false)
      const hotRef = ref(null)

      const getCurrentYearMonth = () => {
        const currentDate = new Date()
        const year = currentDate.getFullYear()
        const month = String(currentDate.getMonth() + 1).padStart(2, '0')
        return `${year}-${month}`
      }

      const date = ref(getCurrentYearMonth())

      const message = reactive({
        text: '',
        isVisible: false,
      })
      /*
       **************************************** CALCULATE SALARY ****************************************
       */

      const commonRenderer = (instance, td, row, col, prop, value) => {
        td.classList.add('htCenter', 'htMiddle')
        td.style.color = '#828282'

        const displayValue = value !== null && value !== undefined ? value : ''

        const rowData = hotRef.value.hotInstance.getDataAtRow(row)

        if (!rowData[0]) {
          td.style.backgroundColor = '#ffc2c2'
        }

        if (col == 0) {
          const checkbox = document.createElement('input')
          checkbox.type = 'checkbox'
          checkbox.checked = !!value

          checkbox.addEventListener('change', () => {
            const newValue = checkbox.checked
            console.log(newValue)

            instance.setDataAtCell(row, col, newValue)
          })

          td.innerHTML = ''
          td.appendChild(checkbox)
        } else if (col == 1) {
          td.innerHTML = date.value
        } else if (col == 6) {
          const formattedValue = parseInt(value).toLocaleString()
          td.innerHTML = formattedValue
        } else if ((col >= 7 && col <= 9) || col == 16) {
          const inputWidth = col === 9 ? 'w-4/5' : 'w-1/2'
          const formattedValue = !isNaN(parseFloat(displayValue)) ? parseInt(displayValue).toLocaleString() : displayValue
          td.innerHTML = `<input type="text" value="${formattedValue}" class="border border-[#ccc] p-0.5 m-0.5 text-center rounded-sm ${inputWidth}"/>`

          const inputElement = td.querySelector('input')

          inputElement.addEventListener('blur', () => {
            const newValue = inputElement.value

            instance.setDataAtCell(row, col, newValue)
          })
        } else {
          td.innerHTML = displayValue
        }

        const column6Value = parseFloat(instance.getDataAtCell(row, 6)) || 0
        const column7Value = parseFloat(instance.getDataAtCell(row, 7)) || 0
        const column8Value = parseFloat(instance.getDataAtCell(row, 8)) || 0
        const column9Value = parseFloat(instance.getDataAtCell(row, 9)) || 0

        const column16Value = parseFloat(instance.getDataAtCell(row, 16)) || 0

        let calculatedSalary = (column6Value / column7Value) * column8Value + column9Value
        calculatedSalary = isNaN(calculatedSalary) ? 0 : calculatedSalary

        const niigmiinDaatgalShimtgel = calculatedSalary >= 4200000 ? 483000 : calculatedSalary * 0.115
        const afterTax = calculatedSalary - niigmiinDaatgalShimtgel
        const haoatShimtgel = afterTax * 0.1

        const hunglult =
          afterTax <= 500000
            ? 20000
            : afterTax <= 1000000
            ? 18000
            : afterTax <= 1500000
            ? 16000
            : afterTax <= 2000000
            ? 14000
            : afterTax <= 2500000
            ? 12000
            : afterTax <= 3000000
            ? 10000
            : 0

        const hoaot = haoatShimtgel - hunglult
        const niitSuutgal = niigmiinDaatgalShimtgel + hoaot + column16Value

        if (col == 10) {
          td.innerHTML = calculatedSalary.toLocaleString()
        } else if (col == 11) {
          td.innerHTML = niigmiinDaatgalShimtgel.toLocaleString()
        } else if (col == 12) {
          td.innerHTML = afterTax.toLocaleString()
        } else if (col == 13) {
          td.innerHTML = haoatShimtgel.toLocaleString()
        } else if (col == 14) {
          const hunglultValue = afterTax > 0 ? hunglult : 0
          td.innerHTML = hunglultValue.toLocaleString()
        } else if (col == 15) {
          const hoaotValue = afterTax > 0 ? hoaot : 0
          td.innerHTML = hoaotValue.toLocaleString()
        } else if (col == 17) {
          const niitSuutgalValue = afterTax > 0 ? niitSuutgal : 0
          td.innerHTML = niitSuutgalValue.toLocaleString()
        } else if (col == 18) {
          const finalSalary = afterTax > 0 ? calculatedSalary - niitSuutgal : 0
          td.innerHTML = finalSalary.toLocaleString()
        } else if (col == 19) {
          td.innerHTML = (calculatedSalary * 0.125).toLocaleString()
        }
      }

      const hotSettings = {
        licenseKey: 'non-commercial-and-evaluation',
        stretchH: 'all',
        height: 'auto',
        rowHeights: 35,
        // fixedColumnsStart: 3,
        // manualColumnFreeze: true,
        // rowHeaders: true,
        hiddenColumns: {
          columns: [5],
          indicators: true,
        },
        colHeaders: [
          '',
          'Огноо',
          'Ажилтны овог',
          'Ажилтны нэр',
          'Регистрийн дугаар',
          'id',
          'Үндсэн цалин',
          'Ажиллах хоног',
          'Ажилсан хоног',
          'Нэмэгдэл',
          'Бодогдсон цалин',
          'НДШ',
          'Даатгалын дараах',
          'ХАОАТ -10%',
          'Хөнгөлөлт',
          'ХАОАТ',
          'Бусад суутгал',
          'Нийт суутгал',
          'Гарт олгох цалин',
          'Байгууллага НДШ',
        ],
        columns: [
          { data: 'checked', readOnly: true, renderer: commonRenderer },
          { data: 'date', readOnly: true, renderer: commonRenderer },
          { data: 'last_name', readOnly: true, renderer: commonRenderer },
          { data: 'first_name', readOnly: true, renderer: commonRenderer },
          { data: 'register_num', readOnly: true, renderer: commonRenderer },
          { data: 'salary_id', readOnly: true, renderer: commonRenderer },
          { data: 'basic_salary', readOnly: true, renderer: commonRenderer },
          { data: 'ajillah_honog', readOnly: true, renderer: commonRenderer },
          { data: 'ajillasan_honog', readOnly: true, renderer: commonRenderer },
          { data: 'nemegdel', readOnly: true, renderer: commonRenderer },
          { data: 'bodogdson_tsalin', readOnly: true, renderer: commonRenderer },
          { data: 'niigmiin_daatgal_shimtgel', readOnly: true, renderer: commonRenderer },
          { data: 'tax_daraah_tsalin', readOnly: true, renderer: commonRenderer },
          { data: 'hhoat_shimtgel', readOnly: true, renderer: commonRenderer },
          { data: 'tatvar_hunglult', readOnly: true, renderer: commonRenderer },
          { data: 'hhoat', readOnly: true, renderer: commonRenderer },
          { data: 'busad_suutgal', readOnly: true, renderer: commonRenderer },
          { data: 'niit_suutgal', readOnly: true, renderer: commonRenderer },
          { data: 'gart_olgoh_tsalin', readOnly: true, renderer: commonRenderer },
          { data: 'niigmiin_daatgal_shimtgel_baiguullaga', readOnly: true, renderer: commonRenderer },
        ],
        colWidths: (index) => ([0].includes(index) ? 35 : 140),
      }

      /*
       **************************************** SHOW SALARY LIST ****************************************
       */

      const data = computed(() => store.getters['bowsys/getSalaryList'])

      onMounted(async () => {
        try {
          isLoading.value = true
          const message = await store.dispatch('bowsys/fetchSalaryList', { date: date.value })

          if (message === 'successfully') isLoading.value = false
        } catch (error) {
          console.error(error)
          throw error
        }
      })

      watch(data, (newVal, oldVal) => {
        if (newVal !== oldVal && hotRef.value) {
          hotRef.value.hotInstance.loadData(newVal.list)
        }
      })

      /*
       **************************************** UPDATE SALARY LIST BY DATE ****************************************
       */

      const updateDate = async (newDate) => {
        date.value = newDate
        try {
          isLoading.value = true
          const message = await store.dispatch('bowsys/fetchSalaryList', { date: date.value })

          if (message === 'successfully') {
            isLoading.value = false
          }
        } catch (error) {
          console.error(error)
          throw error
        }
      }

      /*
       **************************************** SAVE SALARY ****************************************
       */

      const saveSalaryAllEmployees = async () => {
        const salaryData = hotRef.value.hotInstance.getData()
        const filteredData = salaryData.filter((row) => row[0] === true)
        try {
          const res = await store.dispatch('bowsys/calculateSalary', { date: date.value, data: filteredData })
          if (res.message == 'successfully') {
            message.isVisible = true
            message.text = `${date.value} сарын цалин тооцоолол амжилттай хадгалагдлаа.`

            await store.dispatch('bowsys/fetchSalaryList', { date: date.value })

            setTimeout(() => {
              message.isVisible = false
            }, 3000)
          }
        } catch (error) {
          console.error(error)
          throw error
        }
      }

      return { hotSettings, isLoading, date, updateDate, hotRef, saveSalaryAllEmployees, message, data }
    },
  }
</script>

<style>
  .custom-salary .ht_clone_top th {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
    background-color: #fcfcfc !important;
    color: #828282 !important;
  }

  .custom-salary .ht_master .wtHolder .wtHider .wtSpreader table td,
  .custom-salary .ht_master .wtHolder .wtHider .wtSpreader table th {
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-top: none !important;
  }

  .custom-salary .ht_master .wtHolder .wtHider .wtSpreader .htBorders div .wtBorder {
    background-color: inherit !important;
  }

  .custom-salary .ht_master .wtHolder .wtHider .wtSpreader table td {
    border-top: 1px solid #f3f4f5 !important;
  }
</style>
