<template>
  <div v-if="isOpen" class="fixed inset-0 z-[9997] flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white rounded-lg shadow-lg w-full p-6" :class="modalWidth ? modalWidth : ''">
      <div class="flex justify-between items-center">
        <h3 class="text-base font-semibold text-grayFont">{{ title }}</h3>
        <button @click="closeModal" class="text-gray-400 hover:text-gray-600 text-lg">&times;</button>
      </div>
      <div>
        <p v-if="message.isVisible" class="my-2 font-bold" :class="message.text === 'Амжилттай.' ? 'text-green-500' : 'text-red-500'">
          {{ message.text }}
        </p>
      </div>
      <div class="mt-4 text-xs text-gray-400">
        <slot></slot>
      </div>
      <div class="mt-6 flex justify-end gap-3">
        <button @click="saveModal" class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">Хадгалах</button>
        <button @click="closeModal" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Хаах</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ModalCom',
    props: {
      isOpen: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        default: 'Modal Title',
      },
      modalWidth: {
        type: String,
        default: 'max-w-lg',
      },
      message: {
        type: Object,
      },
    },
    emits: ['close', 'save'],
    setup(props, { emit }) {
      const closeModal = () => {
        emit('close')
      }

      const saveModal = () => {
        emit('save')
      }

      return {
        closeModal,
        saveModal,
      }
    },
  }
</script>
