<template>
  <div class="text-xs">
    <div class="bg-[#022239]">
      <div class="flex justify-between items-center px-4 py-6">
        <img src="../../../assets/bowsys.png" class="w-32" alt="" />
        <div class="flex items-center gap-4 font-bold text-bgColor">
          <input type="text" class="border p-2 w-96 rounded-sm bg-gray-200 text-gray-600" placeholder="Search ..." />
          <button class="bg-[#02324B] h-10 flex items-center gap-4 px-3 rounded-sm">
            {{ formattedDate }}
            <font-awesome-icon icon="fa-calendar" class="h-3 w-3" />
          </button>
          <button class="bg-[#02324B] h-10 flex items-center gap-4 px-3 rounded-sm">
            Bowsys
            <font-awesome-icon icon="fa-chevron-down" class="h-3 w-3" />
          </button>
          <button class="flex items-center justify-center gap-2 bg-[#02324B] rounded-sm h-10 px-4">
            <font-awesome-icon icon="fa-user" class="h-3 w-3" />

            <p>User</p>
          </button>
          <!-- <div v-if="organizations && organizations.data">
              <DropDown :data="{ text: companyName, list: organizations.data }" />
            </div> -->
        </div>
      </div>

      <div class="flex items-center gap-2 text-gray-400 px-4 py-2">
        <router-link
          v-for="r in routes"
          :key="r.name"
          :to="r.path"
          :class="isMainRoute == r.name ? 'border-b border-gray-100 text-gray-100' : ''"
          @click="setSelectedRoute(r)"
          class="flex gap-2 p-2 items-center"
        >
          <font-awesome-icon :icon="r.icon" class="h-3 w-3" />
          {{ r.name }}
        </router-link>
      </div>
    </div>
    <div class="bg-gray-200 flex items-center gap-6 h-10 px-4" v-if="selectedRoute?.children">
      <router-link v-for="child in selectedRoute?.children" :key="child.name" :to="child.path" :class="isCurrentRoute(child.path)">
        {{ child.name }}
      </router-link>
    </div>
    <div class="min-h-screen p-4 bg-[#FCFCFC]">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import { ref, computed } from 'vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'

  export default {
    name: 'BowsysNavbar',
    components: {},
    setup() {
      const route = useRoute()
      const store = useStore()
      const organizations = ref([])
      const companyName = ref('')

      const setSelectedRoute = async (route) => {
        await store.dispatch('bowsys/fetchRoute', route)
      }

      const routes = ref([
        {
          name: 'Данс бүртгэл',
          path: '/bowsys/accounts-list',
          icon: 'fa-pen',
        },
        {
          name: 'Цалин',
          icon: 'fa-wallet',
          path: '/bowsys/employee',
          children: [
            { name: '1. Ажилтаны мэдээлэл', path: '/bowsys/employee' },
            { name: '2. Цалин тооцоо', path: '/bowsys/salary' },
            { name: '3. Үзүүлэлт', path: '/bowsys/statistics' },
          ],
        },
        {
          name: 'Бараа материал',
          path: '/bowsys/goods',
          icon: 'fa-brands fa-product-hunt',
        },
        {
          path: '/bowsys/fixed-asstes',
          name: 'Үндсэн хөрөнгө',
          icon: 'fa-building-user',
        },
        {
          path: '/bowsys/general-journal',
          name: 'Ерөнхий журнал',
          icon: 'fa-book',
        },
        {
          path: '/bowsys/balance',
          name: 'Гүйлгээ баланс',
          icon: 'fa-scale-balanced',
        },
        {
          name: 'Тайлан',
          icon: 'fa-layer-group',
          path: '/bowsys/ct1',
          children: [
            { path: '/bowsys/ct1', name: '1. Ct_1' },
            { path: '/bowsys/ct2', name: '2. Ct_2' },
            { path: '/bowsys/ct3', name: '3. Ct_3' },
            { path: '/bowsys/ct4', name: '4. Ct_4' },
          ],
        },
      ])

      const selectedRoute = computed(() => store.getters['bowsys/getRoute'])
      const isMainRoute = computed(() => selectedRoute.value?.name || '')

      const isCurrentRoute = (path) => {
        return {
          'font-bold text-[#022239]': route.path === path,
          'text-gray-600': route.path !== path,
        }
      }

      const formattedDate = computed(() => {
        return new Date().toLocaleDateString('en-CA')
      })

      return {
        routes,
        setSelectedRoute,
        selectedRoute,
        isCurrentRoute,
        isMainRoute,
        organizations,
        companyName,
        formattedDate,
      }
    },
  }
</script>
