<template>
  <NavBar>
    <br />
    <div class="flex flex-col items-center text-red-500" v-if="!data">
      <h1>Мэдээлэл олдсонгүй!!!</h1>
    </div>

    <div class="flex flex-col items-center" v-if="data">
      <h1>7. Хяналт</h1>
      <hot-table :settings="hotSettings" :data="data" class="custom-hot-table">
      </hot-table>
    </div>
  </NavBar>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import Handsontable from "handsontable";
import "handsontable/dist/handsontable.full.css";
import NavBar from "@/components/ui/NavBar.vue";

Handsontable.renderers.registerRenderer(
  "customStylesRenderer",
  (hotInstance, TD, ...rest) => {
    Handsontable.renderers.TextRenderer(hotInstance, TD, ...rest);
    TD.style.fontWeight = "bold";
  }
);

registerAllModules();

export default {
  name: "HynaltHu",
  components: {
    HotTable,
    NavBar,
  },
  setup() {
    const store = useStore();
    const data = ref();

    onMounted(async () => {
      try {
        await store.dispatch("fetchP7");
        data.value = store.getters.getP7;
      } catch (error) {
        return error;
      }
    });

    const hotSettings = {
      licenseKey: "non-commercial-and-evaluation",
    };

    return {
      data,
      hotSettings,
    };
  },
};
</script>
