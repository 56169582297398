<template>
  <RouterView />
</template>
<script>
import { defineComponent } from "vue";
import "handsontable/dist/handsontable.full.css";

export default defineComponent({
  data() {
    return {};
  },
});
</script>
